// init view with sk in textform

import {round} from "lodash/math";

function initSkText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient', 'Rot', 'Gelb', 'Grün', 'Undefiniert', 'Sk-Gründe', 'Ø-Sk-Zeit aller Gruppen', 'Sk später geändert', 'eFast', 'NST'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['Rot', 'Gelb', 'Gruen', 'na', 'reasons', 'time', 'change', 'eFast', 'NST']) {
                if (label === "time")
                    data.data[patientid][label] = 0;
                else
                    data.data[patientid][label] = [];
            }
        }

        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)){
            let sktime = patient.sk_action - patient.arrived;
            sktime = sktime > 300 || sktime < -300 ? 300 : sktime;
            switch (patient.sk) {
                case "rot":
                    data.data[patientid].Rot.push(groupid);
                    data.data[patientid].time += sktime
                    break;
                case "gelb":
                    data.data[patientid].Gelb.push(groupid);
                    data.data[patientid].time += sktime
                    break;
                case "grün":
                    data.data[patientid].Gruen.push(groupid);
                    data.data[patientid].time += sktime
                    break;
                default:
                    data.data[patientid].na.push(groupid);
                    break;
            }
            if (patient.efast) {
                data.data[patientid].eFast.push(groupid);
            }
            if (patient.nst) {
                data.data[patientid].NST.push(groupid);
            }
            if (patient.sk_loc !== "Sichtungsplatz" && patient.sk_loc !== "")
                data.data[patientid].change.push(groupid);

            let reason = patient.sk_reason;
            if (reason.length > 20) {
                reason = reason.substring(0, 20) + "...";
            }

            let found = false
            for (let i = 0; i < data.data[patientid].reasons.length; i++) {
                if (data.data[patientid].reasons[i].includes(reason)) {
                    let count = parseInt(data.data[patientid].reasons[i][0])
                    data.data[patientid].reasons[i] = ++count +"x " + reason;
                    found = true
                    break
                }
            }

            if (!found)
                data.data[patientid].reasons.push("1x " + reason);
        }
    }

    for (const entry of Object.entries(data.data)) {
        entry[1].time = round(entry[1].time / (entry[1].Rot.length + entry[1].Gelb.length + entry[1].Gruen.length));

    }



    return data;
}

// init view with sk in graphform
function initSkGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient', 'SK', 'eFast', 'NST'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['SK', 'eFast', 'NST']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        let percentSKData = new Array(4).fill(0);
        let percenteFastData = new Array(4).fill(0);
        let percentNSTData = new Array(4).fill(0);

        for(const [groupid, patient] of Object.entries(groups)){
            switch (patient.sk) {
                case "rot":
                    percentSKData[0]++;
                    break;
                case "gelb":
                    percentSKData[1]++;
                    break;
                case "grün":
                    percentSKData[2]++;
                    break;
                case "undefined":
                    percentSKData[3]++;
                    break;
            }
            if (patient.efast) {
                percenteFastData[0]++;
            } else {
                percenteFastData[1]++;
            }
            if (patient.nst) {
                percentNSTData[0]++;
            } else {
                percentNSTData[1]++;
            }
        }

        // set the data structure for a SK chart.
        data.data[patientid].SK = {
            labels: ['Rot', 'Gelb', 'Grün', 'Undefiniert'],
            datasets: [
                {
                    data: percentSKData,
                    backgroundColor: ["rgba(178,34,34,0.75)", "rgba(255,215,0,0.75)", "rgba(34,139,34,0.75)", "rgba(128,128,128,0.75)"],
                    hoverBackgroundColor: ["rgba(178,34,34,0.5)", "rgba(255,215,0,0.5)", "rgba(34,139,34,0.5)", "rgba(128,128,128,0.5)"]
                }
            ]
        };
        // set the data structure for a eFast chart.
        data.data[patientid].eFast = {
            labels: ['Ja', 'Nein'],
            datasets: [
                {
                    data: percenteFastData,
                    backgroundColor: ["rgba(0,102,0,0.75)", "rgba(178,34,34,0.75)"],
                    hoverBackgroundColor: ["rgba(0,102,0,0.5)", "rgba(178,34,34,0.5)"]
                }
            ]
        };
        // set the data structure for a NST chart.
        data.data[patientid].NST = {
            labels: ['Ja', 'Nein'],
            datasets: [
                {
                    data: percentNSTData,
                    backgroundColor: ["rgba(0,102,0,0.75)", "rgba(178,34,34,0.75)"],
                    hoverBackgroundColor: ["rgba(0,102,0,0.5)", "rgba(178,34,34,0.5)"]
                }
            ]
        };
    }
    return data;
}

// init view with sk in textform
function initLPText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient', '1', '2', '3', '4', '5', '6', 'tot', 'Durchschnitt'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['one', 'two', 'three', 'four', 'five', 'six', 'tot', 'schnitt']) {
                data.data[patientid][label] = [];
            }
        }
        let sum = 0;
        let groupsCount = 0;
        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)){
            switch (patient.lp) {
                case 0:
                    data.data[patientid].tot.push(groupid);
                    break;
                case 1:
                    data.data[patientid].one.push(groupid);
                    break;
                case 2:
                    data.data[patientid].two.push(groupid);
                    break;
                case 3:
                    data.data[patientid].three.push(groupid);
                    break;
                case 4:
                    data.data[patientid].four.push(groupid);
                    break;
                case 5:
                    data.data[patientid].five.push(groupid);
                    break;
                case 6:
                    data.data[patientid].six.push(groupid);
                    break;
            }
            sum += patient.lp;
            groupsCount++;
        }

        data.data[patientid].schnitt.push((sum / groupsCount).toFixed(2));
    }
    return data;
}

// init view with sk in graphform
function initLPGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient', 'Lebenspunkte (alle Gruppen)', 'Lebendig (alle Gruppen)'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['lp', 'alive']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        let percentLPData = new Array(7).fill(0);

        let percenteAliveData = new Array(2).fill(0);

        for(const [groupid, patient] of Object.entries(groups)){
            switch (patient.lp) {
                case 0:
                    percentLPData[0]++;
                    percenteAliveData[0]++;
                    break;
                case 1:
                    percentLPData[1]++;
                    percenteAliveData[1]++;
                    break;
                case 2:
                    percentLPData[2]++;
                    percenteAliveData[1]++;
                    break;
                case 3:
                    percentLPData[3]++;
                    percenteAliveData[1]++;
                    break;
                case 4:
                    percentLPData[4]++;
                    percenteAliveData[1]++;
                    break;
                case 5:
                    percentLPData[5]++;
                    percenteAliveData[1]++;
                    break;
                case 6:
                    percentLPData[6]++;
                    percenteAliveData[1]++;
                    break;
            }
        }

        // set the data structure for a SK chart.
        data.data[patientid].lp = {
            labels: ['tot','1', '2', '3', '4', '5', '6'],
            datasets: [
                {
                    data: percentLPData,
                    label: "Lebenspunkte (pro Gruppe)",
                    backgroundColor: ["rgba(178,34,34,0.75)"],
                    hoverBackgroundColor: ["rgba(178,34,34,0.5)"]
                }
            ]
        };
        // set the data structure for a eFast chart.
        data.data[patientid].alive = {
            labels: ['Verstorben', 'Lebendig'],
            datasets: [
                {
                    data: percenteAliveData,
                    label: "Patientenstatus (pro Gruppe)",
                    backgroundColor: ["rgba(178,34,34,0.75)"],
                    hoverBackgroundColor: ["rgba(178,34,34,0.5)"]
                }
            ]
        };
    }
    return data;
}

// init view with sk in textform
function initDiagnoseText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient','EFast Sichtungsplatz', 'Efast woanders', 'Kein Efast', 'Röntgen', 'CT'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['efast_sichtung', 'efast', 'no_efast', 'xray', 'ct']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)){
            let therapies = patient.therapies;
            if (therapies[0])
                data.data[patientid].efast_sichtung.push(groupid);
            else if (therapies[1])
                data.data[patientid].efast.push(groupid);
            else
                data.data[patientid].no_efast.push(groupid);
            if (therapies[2])
                data.data[patientid].xray.push(groupid);
            if (therapies[3])
                data.data[patientid].ct.push(groupid);
        }
    }
    return data;
}

// init view with sk in graphform
function initDiagnoseGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient', 'Efast', 'Röntgen', 'CT'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['efast', 'rontgen', 'ct']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        let percentEfastData = new Array(3).fill(0);
        let percenteRontgenData = new Array(2).fill(0);
        let percentCTData = new Array(2).fill(0);

        for(const [groupid, patient] of Object.entries(groups)){
            let therapies = patient.therapies;
            if (therapies[0])
                percentEfastData[0]++;
            else if (therapies[1])
                percentEfastData[1]++;
            else
                percentEfastData[2]++;
            if (therapies[2])
                percenteRontgenData[0]++;
            else
                percenteRontgenData[1]++;
            if (therapies[3])
                percentCTData[0]++;
            else
                percentCTData[1]++;
        }

        // set the data structure for a SK chart.
        data.data[patientid].efast = {
            labels: ['EFast Sichtungsplatz', 'Efast woanders', 'Kein Efast'],
            datasets: [
                {
                    data: percentEfastData,
                    backgroundColor: ["rgba(178,34,34,0.75)", "rgba(255,215,0,0.75)", "rgba(34,139,34,0.75)", "rgba(128,128,128,0.75)"],
                    hoverBackgroundColor: ["rgba(178,34,34,0.5)", "rgba(255,215,0,0.5)", "rgba(34,139,34,0.5)", "rgba(128,128,128,0.5)"]
                }
            ]
        };
        // set the data structure for a eFast chart.
        data.data[patientid].rontgen = {
            labels: ['Ja', 'Nein'],
            datasets: [
                {
                    data: percenteRontgenData,
                    backgroundColor: ["rgba(0,102,0,0.75)", "rgba(178,34,34,0.75)"],
                    hoverBackgroundColor: ["rgba(0,102,0,0.5)", "rgba(178,34,34,0.5)"]
                }
            ]
        };
        // set the data structure for a NST chart.
        data.data[patientid].ct = {
            labels: ['Ja', 'Nein'],
            datasets: [
                {
                    data: percentCTData,
                    backgroundColor: ["rgba(0,102,0,0.75)", "rgba(178,34,34,0.75)"],
                    hoverBackgroundColor: ["rgba(0,102,0,0.5)", "rgba(178,34,34,0.5)"]
                }
            ]
        };
    }
    return data;
}

// init view with sk in textform
function initTherapieStepsText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient','ETC', 'DCS', 'TASC', 'Konservative Therapie', 'Keine Therapie'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['etc', 'dcs', 'tas', 'kons', 'keine']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)){
            let therapies = patient.therapies;
            if (therapies[4])
                data.data[patientid].etc.push(groupid);
            if (therapies[5])
                data.data[patientid].dcs.push(groupid);
            if (therapies[6])
                data.data[patientid].tas.push(groupid);
            if (therapies[7])
                data.data[patientid].kons.push(groupid);
            if (therapies[8])
                data.data[patientid].keine.push(groupid);
        }
    }
    return data;
}

// init view with sk in textform
function initTherapieStepsGroupsText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Gruppe','ETC', 'DCS', 'TASC', 'Konservative Therapie', 'Keine Therapie'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)) {
            if (!(groupid in data.data)) {
                data.data[groupid] = {};
                // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
                for(const label of ['etc', 'dcs', 'tas', 'kons', 'keine']) {
                    data.data[groupid][label] = 0;
                }
            }
            let therapies = patient.therapies;
            if (therapies[4])
                data.data[groupid].etc++;
            if (therapies[5])
                data.data[groupid].dcs++;
            if (therapies[6])
                data.data[groupid].tas++;
            if (therapies[7])
                data.data[groupid].kons++;
            if (therapies[8])
                data.data[groupid].keine++;
        }
    }

    return data;
}

// init view with sk in graphform
function initTherapieStepsGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient', 'Therapie'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['therapies']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        let percentTherapieData = new Array(5).fill(0);

        for(const [groupid, patient] of Object.entries(groups)){
            let therapies = patient.therapies;
            if (therapies[4])
                percentTherapieData[0]++;
            if (therapies[5])
                percentTherapieData[1]++;
            if (therapies[6])
                percentTherapieData[2]++;
            if (therapies[7])
                percentTherapieData[3]++;
            if (therapies[8])
                percentTherapieData[4]++;
        }

        // set the data structure for a SK chart.
        data.data[patientid].therapies = {
            labels: ['ETC', 'DCS', 'TASK', 'Konservative Therapie', 'Keine Therapie'],
            datasets: [
                {
                    data: percentTherapieData,
                    backgroundColor: ["rgba(178,34,34,0.75)"],
                    hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                    label: "Therapien aller Patienten",
                },
            ]
        };
    }
    return data;
}

// init view with sk in graphform
function initTherapieStepsGroupsGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Group', 'Therapie'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        for (const [groupid, patient] of Object.entries(groups)) {
            if (!(groupid in data.data)) {
                data.data[groupid] = {};
                // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
                data.data[groupid]["therapies"] = {
                    labels: ['ETC', 'DCS', 'TASK', 'Konservative Therapie', 'Keine Therapie'],
                    datasets: [
                        {
                            data: new Array(5).fill(0),
                            backgroundColor: ["rgba(178,34,34,0.75)"],
                            hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                            label: "Therapien aller Patienten (nach Gruppen)",
                        },
                    ]
                };
            }
            let therapies = patient.therapies;
            if (therapies[4])
                data.data[groupid]["therapies"]["datasets"][0]["data"][0]++;
            if (therapies[5])
                data.data[groupid]["therapies"]["datasets"][0]["data"][1]++;
            if (therapies[6])
                data.data[groupid]["therapies"]["datasets"][0]["data"][2]++;
            if (therapies[7])
                data.data[groupid]["therapies"]["datasets"][0]["data"][3]++;
            if (therapies[8])
                data.data[groupid]["therapies"]["datasets"][0]["data"][4]++;
        }
    }
    return data;
}

// init view with sk in textform
function initLocationText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient','Bb GRÜN', 'Bb GELB', 'Bb Rot', 'Schockraum', 'OP'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['grun', 'gelb', 'rot', 'schockraum', 'op']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)){
            let locations = patient.visited_locations;
            if (locations[0])
                data.data[patientid].grun.push(groupid);
            if (locations[1])
                data.data[patientid].gelb.push(groupid);
            if (locations[2])
                data.data[patientid].rot.push(groupid);
            if (locations[3])
                data.data[patientid].schockraum.push(groupid);
            if (locations[4])
                data.data[patientid].op.push(groupid);
        }
    }
    return data;
}

// init view with sk in textform
function initLocationGroupText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Gruppe','Bb GRÜN', 'Bb GELB', 'Bb Rot', 'Schockraum', 'OP'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)) {
            if (!(groupid in data.data)) {
                data.data[groupid] = {};
                // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
                for(const label of ['grun', 'gelb', 'rot', 'schockraum', 'op']) {
                    data.data[groupid][label] = 0;
                }
            }
            let locations = patient.visited_locations;
            if (locations[0])
                data.data[groupid].grun++;
            if (locations[1])
                data.data[groupid].gelb++;
            if (locations[2])
                data.data[groupid].rot++;
            if (locations[3])
                data.data[groupid].schockraum++;
            if (locations[4])
                data.data[groupid].op++;
        }
    }
    return data;
}

// init view with sk in graphform
function initLocationGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient', 'Station'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['lokation']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        let percentLocationData = new Array(5).fill(0);

        for(const [groupid, patient] of Object.entries(groups)){
            let location = patient.visited_locations;
            if (location[0])
                percentLocationData[0]++;
            if (location[1])
                percentLocationData[1]++;
            if (location[2])
                percentLocationData[2]++;
            if (location[3])
                percentLocationData[3]++;
            if (location[4])
                percentLocationData[4]++;
        }

        // set the data structure for a SK chart.
        data.data[patientid].lokation = {
            labels: ['Bb GRÜN', 'Bb GELB', 'Bb Rot', 'Schockraum', 'OP'],
            datasets: [
                {
                    data: percentLocationData,
                    backgroundColor: ["rgba(178,34,34,0.75)"],
                    hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                    label: "Aufenthaltsort aller Patienten",
                },
            ]
        };
    }
    return data;
}

// init view with sk in graphform
function initLocationGroupGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Gruppe', 'Station'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        for (const [groupid, patient] of Object.entries(groups)) {
            if (!(groupid in data.data)) {
                data.data[groupid] = {};
                // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
                data.data[groupid]["location"] = {
                    labels: ['Bb GRÜN', 'Bb GELB', 'Bb Rot', 'Schockraum', 'OP'],
                    datasets: [
                        {
                            data: new Array(5).fill(0),
                            backgroundColor: ["rgba(178,34,34,0.75)"],
                            hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                            label: "Aufenthaltsort aller Patienten (nach Gruppen)",
                        },
                    ]
                };
            }
            let location = patient.visited_locations;
            if (location[0])
                data.data[groupid]["location"]["datasets"][0]["data"][0]++;
            if (location[1])
                data.data[groupid]["location"]["datasets"][0]["data"][1]++;
            if (location[2])
                data.data[groupid]["location"]["datasets"][0]["data"][2]++;
            if (location[3])
                data.data[groupid]["location"]["datasets"][0]["data"][3]++;
            if (location[4])
                data.data[groupid]["location"]["datasets"][0]["data"][4]++;
        }
    }
    // set the data structure for a SK chart.

    return data;
}

// init view with sk in textform
function initStatusText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient','nicht disponiert', 'disponiert', 'begonnen', 'abgeschlossen'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['nicht', 'disp', 'begon', 'fertig']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)){
            let status = patient.status_therapie;

            // status therapie [0] = nicht disponiert, [1] = disponiert, [2] = begonnen, [3] = abgeschlossen
            if (status[0]) {
                data.data[patientid].nicht.push(groupid);
            }
            if (status[1]) {
                data.data[patientid].disp.push(groupid);
            }
            if (status[2]) {
                data.data[patientid].begon.push(groupid);
            }
            if (status[3]) {
                data.data[patientid].fertig.push(groupid);
            }

        }
    }
    return data;
}

// init view with sk in graphform
function initStatusGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Patient', 'Therapiestatus'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        if (!(patientid in data.data)) {
            data.data[patientid] = {};
            // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
            for(const label of ['status']) {
                data.data[patientid][label] = [];
            }
        }
        // prozess data into their cathegories
        let percentStatusData = new Array(4).fill(0);

        for(const [groupid, patient] of Object.entries(groups)){
            let status = patient.status_therapie;

            // status therapie [0] = nicht disponiert, [1] = disponiert, [2] = begonnen, [3] = abgeschlossen
            if (status[0]) {
                percentStatusData[0]++;
            }
            if (status[1]) {
                percentStatusData[1]++;
            }
            if (status[2]) {
                percentStatusData[2]++;
            }
            if (status[3]) {
                percentStatusData[3]++;
            }
        }

        // set the data structure for a SK chart.
        data.data[patientid].status = {
            labels: ['nicht disponiert', 'Disponiert', 'Begonnen', 'Abgeschlossen'],
            datasets: [
                {
                    data: percentStatusData,
                    backgroundColor: ["rgba(178,34,34,0.75)"],
                    hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                    label: "Therapiestatus der Patienten",
                },
            ]
        };
    }
    return data;
}

// init view with sk in textform
function initPerformanceText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Gruppe','Summe Lebenspunkte', 'Score ', 'Warterunden', 'Ø-Sk-Zeit in Sekunden', 'nicht disponiert', 'disponiert', 'begonnen', 'abgeschlossen'];
    // data that is filled into the rows
    data.data = {};
    let skValues = {};


    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)) {
            if (!(groupid in data.data)) {
                data.data[groupid] = {};
                // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
                for(const label of ['lp', 'total_score', 'waiting', 'sk', 'nicht', 'disp', 'begon', 'fertig']) {
                    data.data[groupid][label] = 0;
                }
                skValues[groupid] = [];
            }
            data.data[groupid].lp += patient.lp;
            data.data[groupid].total_score += patient.score + patient.lp;
            data.data[groupid].waiting += patient.waiting_rounds;

            let skTime = patient.sk_action - patient.arrived;
            if (skTime > 0)
                skValues[groupid].push(skTime);

            //data.data[groupid].sk.push(patient.sk_action - patient.arrived);
            let status = patient.status_therapie;

            // status therapie [0] = nicht disponiert, [1] = disponiert, [2] = begonnen, [3] = abgeschlossen
            if (status[0]) {
                data.data[groupid].nicht++;
            }
            if (status[1]) {
                data.data[groupid].disp++;
            }
            if (status[2]) {
                data.data[groupid].begon++;
            }
            if (status[3]) {
                data.data[groupid].fertig++;
            }
        }
    }

    for(let i = 0; i < Object.entries(data.data).length; i ++) {
        let valueToAdd = 0;
        let values = Object.entries(skValues)[i];

        for(let i = 0; i < values[1].length; i++) {
            valueToAdd += values[1][i];
        }
        data.data[values[0]].sk = Math.round(valueToAdd / (values[1].length));
    }
    return data;
}

// init view with sk in graphform
function initPerformanceGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Wert', 'Graph'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */

    let scores = {};
    let lp = {};
    let wait = {};
    let sk = {};
    let skValues = {};


    for(const [patientid, groups] of Object.entries(groupresults)) {
        for (const [groupid, patient] of Object.entries(groups)) {
            if(!scores.hasOwnProperty(groupid))
                scores[groupid] = 0;
            scores[groupid] += patient.score + patient.lp
            if(!lp.hasOwnProperty(groupid))
                lp[groupid] = 0;
            lp[groupid] += patient.lp;

            if(!wait.hasOwnProperty(groupid))
                wait[groupid] = 0;
            wait[groupid] += patient.waiting_rounds;

            if(!sk.hasOwnProperty(groupid)) {
                sk[groupid] = 0;
                skValues[groupid] = [];
            }

            let skTime = patient.sk_action - patient.arrived;
            if (skTime > 0)
                skValues[groupid].push(skTime);
        }
    }

    for(let i = 0; i < Object.entries(skValues).length; i ++) {
        let valueToAdd = 0;
        let values = Object.entries(skValues)[i];
        values[1].sort(function(a, b) {
            return a - b;
        });

        for(let i = 1; i < values[1].length -1; i++) {
            valueToAdd += values[1][i];
        }
        sk[values[0]] = Math.round(valueToAdd / (values[1].length - 2));
    }

    data.data["Lebenspunkte"] = {}
    data.data["Lebenspunkte"]["lp"] = {
        labels: Object.keys(lp),
        datasets: [
            {
                data: Object.values(lp),
                backgroundColor: ["rgba(178,34,34,0.75)"],
                hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                label: "Gesamt Lebenspunkte",
            },
        ]
    };
    data.data["Gesamtpunkte"] = {}
    data.data["Gesamtpunkte"]["scores"] = {
        labels: Object.keys(scores),
        datasets: [
            {
                data: Object.values(scores),
                backgroundColor: ["rgba(178,34,34,0.75)"],
                hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                label: "Gesamt Punkte",
            },
        ]
    };

    data.data["WarteRunden"] = {}
    data.data["WarteRunden"]["wait"] = {
        labels: Object.keys(wait),
        datasets: [
            {
                data: Object.values(wait),
                backgroundColor: ["rgba(178,34,34,0.75)"],
                hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                label: "Gesamt Warterunden",
            },
        ]
    };

    data.data["Av.Sk-Zeit in Sekunden"] = {}
    data.data["Av.Sk-Zeit in Sekunden"]["sk"] = {
        labels: Object.keys(sk),
        datasets: [
            {
                data: Object.values(sk),
                backgroundColor: ["rgba(178,34,34,0.75)"],
                hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                label: "Durchschnittliche Zeit zum Festlegen des SK in Sekunden",
            },
        ]
    };

    return data;
}

// init view with sk in textform
function initBedCoverText(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Gruppe','Vor dem Krankenhaus','Sichtungsplatz', 'Bb Grün ', 'Bb Gelb', 'Bb Rot', 'Radiologie', 'Schockraum', 'OP', 'IMC', 'ICU', 'NST'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        // prozess data into their cathegories
        for(const [groupid, patient] of Object.entries(groups)) {
            if (!(groupid in data.data)) {
                data.data[groupid] = {};
                // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
                for(const label of ['vor', 'sichtung', 'grun', 'gelb', 'rot', 'radio', 'schock', 'op', 'imc', 'icu', 'nst']) {
                    data.data[groupid][label] = [];
                }
            }
            switch (patient.ggwloc) {
                case "vor dem Krankenhaus":
                    data.data[groupid].vor.push(patient.patientid);
                    break;
                case "Sichtungsplatz":
                    data.data[groupid].sichtung.push(patient.patientid);
                    break;
                case "Schockraum":
                    data.data[groupid].schock.push(patient.patientid);
                    break;
                case "Bb ROT/NFA":
                    data.data[groupid].rot.push(patient.patientid);
                    break;
                case "Bb GELB":
                    data.data[groupid].gelb.push(patient.patientid);
                    break;
                case "Bb GRÜN":
                    data.data[groupid].grun.push(patient.patientid);
                    break;
                case "Radiologie":
                    data.data[groupid].radio.push(patient.patientid);
                    break;
                case "OP":
                    data.data[groupid].op.push(patient.patientid);
                    break;
                case "ICU":
                    data.data[groupid].icu.push(patient.patientid);
                    break;
                case "IMC":
                    data.data[groupid].imc.push(patient.patientid);
                    break;
                case "NST":
                    data.data[groupid].nst.push(patient.patientid);
                    break;
            }
        }
    }
    return data;
}

// init view with sk in graphform
function initBedCoverGraph(groupresults) {
    // create datacontainer
    let data = {};
    // labels that get in the table header
    data.labels = ['Gruppe', 'Bettenbelegung'];
    // data that is filled into the rows
    data.data = {};

    /**
     * look if data allready has an entry with this patientid. Otherwise create it.
     * The first column get filled with the keys from data.data, in this case the patientid.
     */
    for(const [patientid, groups] of Object.entries(groupresults)) {
        for (const [groupid, patient] of Object.entries(groups)) {
            if (!(groupid in data.data)) {
                data.data[groupid] = {};
                // setup datastructures for all relevant cathegories. Exclude first because its filled by data.data keys
                data.data[groupid]["location"] = {
                    labels: ['Vor dem Krankenhaus', 'Sichtungsplatz', 'Bb Grün ', 'Bb Gelb', 'Bb Rot', 'Radiologie', 'Schockraum', 'OP', 'IMC', 'ICU', 'NST'],
                    datasets: [
                        {
                            data: new Array(10).fill(0),
                            backgroundColor: ["rgba(178,34,34,0.75)"],
                            hoverBackgroundColor: ["rgba(178,34,34,0.5)"],
                            label: "Aufenthaltsort aller Patienten",
                        },
                    ]
                };
            }
            switch (patient.ggwloc) {
                case "vor dem Krankenhaus":
                    data.data[groupid]["location"]["datasets"][0]["data"][0]++;
                    break;
                case "Sichtungsplatz":
                    data.data[groupid]["location"]["datasets"][0]["data"][1]++;
                    break;
                case "Bb GRÜN":
                    data.data[groupid]["location"]["datasets"][0]["data"][2]++;
                    break;
                case "Bb GELB":
                    data.data[groupid]["location"]["datasets"][0]["data"][3]++;
                    break;
                case "Bb ROT/NFA":
                    data.data[groupid]["location"]["datasets"][0]["data"][4]++;
                    break;
                case "Radiologie":
                    data.data[groupid]["location"]["datasets"][0]["data"][5]++;
                    break;
                case "Schockraum":
                    data.data[groupid]["location"]["datasets"][0]["data"][6]++;
                    break;
                case "OP":
                    data.data[groupid]["location"]["datasets"][0]["data"][7]++;
                    break;
                case "IMC":
                    data.data[groupid]["location"]["datasets"][0]["data"][8]++;
                    break;
                case "ICU":
                    data.data[groupid]["location"]["datasets"][0]["data"][9]++;
                    break;
                case "NST":
                    data.data[groupid]["location"]["datasets"][0]["data"][10]++;
                    break;
            }
        }
    }
    // set the data structure for a SK chart.

    return data;
}

 function intToGameState(value) {
    switch (value) {
        case 0:
            return "Blaue Steine aufsammeln"
        case 1:
            return "Zeit aller Patienten um 1 reduzieren"
        case 2:
            return "Neue Patienten anlegen"
        case 3:
            return "Zwei Ereigniskarten ziehen"
        case 4:
            return "Patienten mit Go bewegen"
        case 5:
            return "Drei Alarmkarten ziehen"
        case 6:
            return "Spielleiter beendet die Runde"
    }
}

// view cathegories
const viewOptions = [
    {name: 'Progress', code: 'progress'},
    {name: 'Sichtungskategorie', code: 'sk'},
    {name: 'Diagnoseschritte', code: 'diag'},
    {name: 'Therapien', code: 'ther'},
    {name: 'Therapieorte', code: 'therort'},
    {name: 'Status Therapie', code: 'stather'},
    {name: 'Lebenspunkte', code: 'lp'},
    {name: 'Performance', code: 'perf'},
    {name: 'Bettenbelegung', code: 'beds'},
]

export default {
    initSkText, initSkGraph, initLPText, initLPGraph, initDiagnoseText,
    initDiagnoseGraph, initTherapieStepsText, initTherapieStepsGroupsText, initTherapieStepsGraph, initTherapieStepsGroupsGraph,
    initLocationText, initLocationGroupText, initLocationGraph, initLocationGroupGraph, initStatusText, initStatusGraph,
    initPerformanceText, initPerformanceGraph, initBedCoverText, initBedCoverGraph, intToGameState,
    viewOptions,
}

