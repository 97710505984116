<template>
    <div>
        <Accordion>
            <AccordionTab v-for="(location, lindex) of Object.entries(this.hospitalLayout.Locations)" :key="lindex" :header="this.hospitalLayout.Locations[lindex].LocationName">
                <table>
                    <tr>
                        <td>Name der Location im Normalbetrieb:</td>
                        <td><InputText type="text" v-model="this.hospitalLayout.Locations[lindex].LocationName" disabled/></td>

                    </tr>
                    <tr>
                        <td>Name der Location im KAEP-Betrieb:</td>
                        <td><InputText type="text" v-model="this.hospitalLayout.Locations[lindex].LocationNameKaep" disabled /></td>
                    </tr>
                    <tr>
                        <td style="padding-top: 20px"><u>Therapien in Normalbetrieb:</u></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div v-for="(therapie, index) of this.hospitalLayout.Locations[lindex].PossibleTherapies">
                                <Dropdown v-model="this.hospitalLayout.Locations[lindex].PossibleTherapies[index]" :options="this.therapies" optionLabel="name" optionValue="code" placeholder="Therapie auswählen" />
                            </div>
                            <div v-if="this.hospitalLayout.Locations[lindex].PossibleTherapies.length === 0">
                                <p class="empty-text">Keine Therapien angelegt!</p>
                            </div>
                            <div class="btn">
                                <Button icon="pi pi-plus" class="p-button-rounded" @click="addTherapieToNormalBetieb(lindex)"/>
                                <Button icon="pi pi-minus" class="p-button-rounded" @click="removeTherapieFromNormalBetrieb(lindex)"/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top: 30px;"><u>Therapien in KAEP-Betrieb:</u></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div v-for="(therapie, index) of this.hospitalLayout.Locations[lindex].PossibleTherapiesKaep">
                                <Dropdown v-model="this.hospitalLayout.Locations[lindex].PossibleTherapiesKaep[index]" :options="this.therapies" optionLabel="name" optionValue="code" placeholder="Therapie auswählen" />
                            </div>

                            <div v-if="this.hospitalLayout.Locations[lindex].PossibleTherapiesKaep.length === 0">
                                <p class="empty-text">Keine Therapien angelegt!</p>
                            </div>
                            <div class="btn">
                                <Button icon="pi pi-plus" class="p-button-rounded" @click="addTherapieToKAEPBetieb(lindex)"/>
                                <Button icon="pi pi-minus" class="p-button-rounded" @click="removeTherapieFromKAEPBetrieb(lindex)"/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top: 30px;">
                            <p><u>Sichtbare PersonalFelder:</u></p>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div style="padding-left: 100px" v-if="this.hospitalLayout.Locations[lindex].VisibleFields.length === 0">
                                <p class="empty-text">Keine Personenfelder angelegt!</p>
                            </div>
                            <div style="max-width: 330px">
                                <PersonalField :VisibleFields="this.hospitalLayout.Locations[lindex].VisibleFields"></PersonalField>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top: 10px;">
                            <div>
                                <p><u>Personalanforderungen:</u></p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <p v-tooltip.right="'Anforderung um einen nicht beatmeten Patienten auf dieser Station versorgen zu können'">Nicht beatmet Versorgung</p>
                            </div>
                        </td>
                        <td>
                            <div>
                                <Textarea v-model="this.hospitalLayout.Locations[lindex].StationPersonalRequirements[0]" auto-resize="true" rows="2" cols="40"/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <p v-tooltip.right="'Anforderung um einen Patienten in diese Station verlegen zu können'">Verlegung</p>
                            </div>
                        </td>
                        <td>
                            <div>

                                <Textarea v-model="this.hospitalLayout.Locations[lindex].StationPersonalRequirements[1]" auto-resize="true" rows="2" cols="40"/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <p v-tooltip.right="'Anforderung um einen beatmeten Patienten auf dieser Station versorgen zu können'">Beatmet versorgen</p>
                            </div>
                        </td>
                        <td>
                            <div>
                                <Textarea v-model="this.hospitalLayout.Locations[lindex].StationPersonalRequirements[2]" auto-resize="true" rows="2" cols="40"/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top: 40px;"><u>Vorhandene Betten:</u></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div>
                                <div style="padding-left:250px" v-if="this.hospitalLayout.Locations[lindex].BedConfigs.length === 0">
                                    <p class="empty-text">Keine Betten angelegt!</p>
                                </div>
                                <BedEntry :BedConfigs="this.hospitalLayout.Locations[lindex].BedConfigs"></BedEntry>
                            </div>
                        </td>
                    </tr>
                </table>
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
import values from '../tdsc-module'
import BedEntry from '../Layout/hospitalBedEntry'
import PersonalField from "../Layout/hospitalPersonalField";

export default {
    components: {BedEntry, PersonalField},
    props: ["hospitalLayout"],
    data() {
        return  {
            therapies: values.therapies,
        }
    },
    methods: {
        // add a therapie to location in normal
        addTherapieToNormalBetieb: function (lindex) {
            this.hospitalLayout.Locations[lindex].PossibleTherapies.push("");
        },
        // remove a therapie from location in normal
        removeTherapieFromNormalBetrieb: function (lindex) {
            this.hospitalLayout.Locations[lindex].PossibleTherapies.pop();
        },
        // add a therapie to location in kaep
        addTherapieToKAEPBetieb: function (lindex) {
            this.hospitalLayout.Locations[lindex].PossibleTherapiesKaep.push("");
        },
        // remove a therapie from location in kaep
        removeTherapieFromKAEPBetrieb: function (lindex) {
            this.hospitalLayout.Locations[lindex].PossibleTherapiesKaep.pop();
        }
    }
}
</script>

<style scoped>

td div {
    padding: 2px;
}

.btn {
    padding: 5px;
}
.btn Button {
    margin-right: 10px;
}
.empty-text {
    font-size: 15px;
    color: #8a9199;
}
</style>
