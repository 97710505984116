/**
 * This model defines a bed inside the scenarioLayoutModel.
 */
export default {
    BedName: "",
    SubTitle: "",
    BedPersonalRequirements: ["", "", ""],
    PossibleTherapies: [],
    PossibleTherapiesKaep: [],
    IsVentilated: false,
    LockedForEventCard: false,
    VisibleFields:[]
}
