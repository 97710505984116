require('./bootstrap');

import {createApp} from 'vue'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import 'primevue/resources/themes/rhea/theme.css'      //theme
import 'primevue/resources/primevue.min.css'                //core css
import 'primeicons/primeicons.css'                          //icons

import main from './components/main'

import SelectButton from 'primevue/selectbutton';
import Chart from 'primevue/chart';
import Button from 'primevue/button';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import TabMenu from 'primevue/tabmenu';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Fieldset from 'primevue/fieldset';
import Divider from 'primevue/divider';
import Checkbox from 'primevue/checkbox';
import Tooltip from 'primevue/tooltip';
import MultiSelect from 'primevue/multiselect';
import Menu from 'primevue/menu';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import OverlayPanel from 'primevue/overlaypanel';
import SplitButton from 'primevue/splitbutton';
import Card from 'primevue/card';

const app = createApp(main);
app.use(PrimeVue);
app.use(Chart);
app.use(ToastService);
app.use(ConfirmationService);

app.directive('tooltip', Tooltip);

app.component('Button', Button);
app.component('Password', Password);
app.component('SelectButton', SelectButton);
app.component('Chart', Chart)
app.component('Dropdown', Dropdown)
app.component('TabMenu', TabMenu)
app.component('InputText', InputText)
app.component('Textarea', Textarea)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Fieldset', Fieldset)
app.component('Divider', Divider)
app.component('Checkbox', Checkbox)
app.component('MultiSelect', MultiSelect)
app.component('Menu', Menu)
app.component('Toast', Toast)
app.component('ConfirmDialog', ConfirmDialog)
app.component('OverlayPanel', OverlayPanel)
app.component('SplitButton', SplitButton)
app.component('Card', Card)

app.mount('#app');

