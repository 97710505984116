/**
 * This model defines a personField inside the scenarioLayoutModel.
 */
export default {
    FieldName: "",
    IsOptional: false,
    IsShared: false,
    Personal: "",
    Person: ""
}
