<template>
    <div>
        <div>
            <table>
                <tr>
                    <td style="padding-top:15px;">
                        <p>Szenarioname:</p>
                    </td>
                    <td>
                        <InputText type="text" class="p-inputtext-sm" v-model="this.gamePlayModel.ScenarioName" />
                    </td>
                </tr>
                <tr>
                    <td style="padding-top:15px;">
                        <p>Krankenhaus-Layout:</p>
                    </td>
                    <td>
                        <Dropdown style="margin-right: 20px" v-model="this.gamePlayModel.LayoutId" :options="this.layouts" optionLabel="name" :filter="true" placeholder="Layout auswählen" :showClear="true">
                            <template #value="slotProps">
                                <div class="country-item country-item-value" v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                        {{slotProps.placeholder}}
                    </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>IntroTexte:</p>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <div v-for="(item, index) of this.gamePlayModel.IntroTexte">
                            <Textarea  v-model="this.gamePlayModel.IntroTexte[index]"  auto-resize="true" rows="3" cols="55"/>
                        </div>
                        <div v-if="this.gamePlayModel.IntroTexte.length === 0">
                            <p class="empty-text">Keine Introtexte angelegt!</p>
                        </div>
                        <div class="btn">
                            <Button icon="pi pi-plus" class="p-button-rounded" @click="addIntroText"/>
                            <Button icon="pi pi-minus" class="p-button-rounded" @click="removeIntroText"/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Rundenablauf:</p>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td style="min-width: 680px">
                        <ScenarioRoundEntry :Rounds="this.gamePlayModel.Rounds"></ScenarioRoundEntry>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import ScenarioRoundEntry from './scenarioRoundEntry'

export default {
    components:{ScenarioRoundEntry},
    props: ['gamePlayModel'],
    methods : {
        // add an introtext to generalmodel. Introtext is displayed to player on scenario begin.
        addIntroText: function () {
            this.gamePlayModel.IntroTexte.push("");
        },
        // remove an introtrext from generalmodel
        removeIntroText: function() {
            this.gamePlayModel.IntroTexte.pop();
        },
        // triggered by deletebutton. Starts dialog to delete this scenario
        deleteRequest: function() {
            if (this.gamePlayModel.ScenarioName === "")
                return;

            this.$confirm.require({
                message: 'Soll dieses Szenario gelöscht werden?',
                header: 'Löschen',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.deleteScenario();
                },
                reject: () => {

                }
            });
        },
        // dialog to delete scenario was commited. Send server request to delete scenario. On response emit event to tell it to parent and trigger view refresh
        deleteScenario: function() {
            axios.get(window.location.href + 'api/codebook/deletescenario?scenarioid=' + this.gamePlayModel["ScenarioName"])
                .then(response => this.$emit('delete'));
        },
        // send request to server asking for all scenarioids
        loadLayoutIds: function() {
            axios.get(window.location.href + 'api/codebook/layoutids')
                .then(response => this.loadLayoutIdsResponse(response));
        },
        // after loadrequest this method is triggered with response and set it in scenarios var
        loadLayoutIdsResponse: function(response) {
            this.layouts = JSON.parse(response["data"].ResponseMessage)["Keys"];
        },
    },
    data() {
        return {
            layouts: [],
        }
    },
    mounted() {
        this.loadLayoutIds();
    }
}
</script>

<style lang="scss" scoped>

.btn {
    padding-left: 160px;
}

.btn Button {
    margin-right: 10px;
}

.scenario-introtext-entry {
    display: inline-flex;
    align-items: center;
    padding-bottom: 10px;
}

.empty-text {
    font-size: 15px;
    color: #8a9199;
    padding-left: 130px;
}

td div {
    padding: 2px;
}
</style>
