<template>
    <div v-if="!this.loading">
        <div class="row">
            <h4>Krankenhaus-Editor</h4>
            <Divider align="right">
                <Dropdown style="margin-right: 20px" v-model="this.selectedLayout" :options="this.layouts" optionLabel="name" :filter="true" placeholder="Layout auswählen" :showClear="true" @change="verifySession">
                    <template #value="slotProps">
                        <div class="country-item country-item-value" v-if="slotProps.value">
                            <div>{{slotProps.value.name}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div>
                            <div>{{slotProps.option.name}}</div>
                        </div>
                    </template>
                </Dropdown>
                <Button style="margin-right: 10px" label="Löschen" icon="pi pi-trash" class="p-button-warning" @click="this.deleteDialog"></Button>
                <Button style="padding-right: 30px" label="Speichern" icon="pi pi-save" class="p-button-success" @click="saveToDatabase"></Button>
            </Divider>
        </div>
        <form>
            <table>
                <tr>
                    <td style="padding-top:15px;">
                        <p>Layoutname:</p>
                    </td>
                    <td>
                        <InputText type="text" class="p-inputtext-sm" v-model="this.hospitalLayoutModel.Layoutid" />
                    </td>
                </tr>
            </table>
            <hospitalLayout @delete="this.delete" :hospitalLayout="this.hospitalLayoutModel"></hospitalLayout>
        </form>
    </div>
    <div class="loading" v-else>
        <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
    </div>
</template>

<script>
import locationLayoutModel from "../Models/hospitalLayout";
import hospitalLayout from "./hospitalLayout";

export default {
    components: {hospitalLayout},
    data() {
        return {
            selectedLayout: "",
            layouts: [],
            loading: false,
            loadedLayout: "",
            scenarioLayoutModelTemplate: locationLayoutModel,
            hospitalLayoutModel: JSON.parse(JSON.stringify(locationLayoutModel)),
        }
    },
    methods: {
        // triggered by savebutton. General and layoutmodel gets pushed to database with scenarioname in generalmodel as id.
        saveToDatabase: function () {
            axios.post(window.location.href + 'api/codebook/layoutstore?layoutid=' + this.hospitalLayoutModel.Layoutid, {data : {"LocationModelCollection" : this.hospitalLayoutModel}})
                .then(response => this.storeLayoutResponse(response));
        },
        // if scenario with same id exists, dialog apears and ask for overwrite this scenario. This method overwrite this scenario in database.
        updateDatabase: function () {
            axios.post(window.location.href + 'api/codebook/layoutupdate?layoutid=' + this.hospitalLayoutModel.Layoutid, {data : {"LocationModelCollection" : this.hospitalLayoutModel}})
                .then(response => this.storeLayoutResponse(response));
        },
        // after models where send to the server, this method is triggered with serverresponse
        storeLayoutResponse: function(response) {
            switch (response["data"]["ResponseCode"]) {
                case 200:
                    this.loadLayoutIds();
                    this.selectedLayout = {name: this.hospitalLayoutModel.Layoutid, code: this.hospitalLayoutModel.Layoutid};
                    this.$toast.add({severity:'success', summary: 'Gespeichert', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
                case 403:
                    this.$toast.add({severity:'error', summary: 'Fehler', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
                case 409: {
                    this.$confirm.require({
                        message: 'Überschreiben?',
                        header: response["data"]["ResponseMessage"],
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => {
                            this.updateDatabase();
                        },
                        reject: () => {

                        }
                    });
                    break;
                }
            }
        },
        // send loadrequest to server for a scenario with given id, set in selectedscenario dropdown
        loadLayout: function() {
            axios.get(window.location.href + 'api/codebook/getlayout?layoutid=' + this.selectedLayout["name"])
                .then(response => this.loadLayoutResponse(response))
        },
        // after loadrequest is send to server, this method is triggered with response that contains layout and general model and set it
        loadLayoutResponse: function(response) {
            this.hospitalLayoutModel = JSON.parse(response["data"]["ResponseMessage"]).LocationModelCollection;
            this.loadedLayout = this.selectedLayout;
            this.loading = false;
        },
        // set all models empty
        loadEmptyValues: function() {
            this.hospitalLayoutModel = JSON.parse(JSON.stringify(this.scenarioLayoutModelTemplate));
            this.selectedLayout = this.selectedLayout;
            this.loading = false;
        },
        // send request to server asking for all scenarioids
        loadLayoutIds: function() {
            axios.get(window.location.href + 'api/codebook/layoutids')
                .then(response => this.loadLayoutIdsResponse(response));
        },
        // after loadrequest this method is triggered with response and set it in scenarios var
        loadLayoutIdsResponse: function(response) {
            this.layouts = JSON.parse(response["data"].ResponseMessage)["Keys"];
        },
        // look if a different key is selected and trigger reload if so
        verifySession: function () {
            if (this.loadedLayout !== this.selectedLayout) {
                this.loading = true;
                if (this.selectedLayout)
                    this.loadLayout();
                else
                    this.loadEmptyValues();
                return false;
            }
            return true;
        },
        deleteDialog: function() {
            this.$confirm.require({
                message: 'Soll das Layout mit Name: -' + this.hospitalLayoutModel.Layoutid + '- wirklich gelöscht werden?',
                header: 'Wirklich löschen?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.delete();
                },
                reject: () => {

                }
            });
        },
        // callback if scenario was deleted. Load scenarioids new and reset view
        delete: function() {
            axios.get(window.location.href + 'api/codebook/deletelayout?layoutid=' + (this.scenarioLayoutModel ? this.scenarioLayoutModel["m_Layoutid"]: ""))
                .then(response => this.deleteResponse(response));
        },
        deleteResponse: function(response) {
            switch (response["data"]["ResponseCode"]) {
                case 200:
                    this.loading = true;
                    this.selectedLayout = null;
                    this.loadLayoutIds();
                    this.verifySession();
                    this.$toast.add({severity:'success', summary: 'Gelöscht', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
                case 403:
                    this.$toast.add({severity:'error', summary: 'Fehler', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
            }
        }
    },
    mounted() {
        // if app starts first of all ask server for scenarios
        this.loadLayoutIds();
    }
}
</script>

<style scoped>
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
</style>
