/**
 * This model setup all the locations inside the codebook.
 * Its filled with default values.
 */

export default {
    "Locations" : [
        {
            LocationName: "AWR",
            LocationNameKaep: "AWR",
            PossibleTherapies: [11, 12],
            PossibleTherapiesKaep: [11, 12],
            BedConfigs: [
            {
                "BedName":"AWR 1",
                "SubTitle":"(wie IMC)",
                "IsVentilated":false,
                "VisibleFields":[

                ],
                "PossibleTherapies":[
                    11,
                    12
                ],
                "LockedForEventCard":false,
                "PossibleTherapiesKaep":[
                    11,
                    12
                ],
                "BedPersonalRequirements":[
                    null,
                    null,
                    "Nein"
                ]
                },
                {
                "BedName":"AWR 2",
                "SubTitle":"(wie IMC)",
                "IsVentilated":false,
                "VisibleFields":[

                ],
                "PossibleTherapies":[
                    11,
                    12
                ],
                "LockedForEventCard":false,
                "PossibleTherapiesKaep":[
                    11,
                    12
                ],
                "BedPersonalRequirements":[
                    null,
                    null,
                    "Nein"
                ]
                },
                {
                "BedName":"AWR 3",
                "SubTitle":"Beatmungsplatz (wie ICU)",
                "IsVentilated":true,
                "VisibleFields":[

                ],
                "PossibleTherapies":[
                    11,
                    12
                ],
                "LockedForEventCard":false,
                "PossibleTherapiesKaep":[
                    11,
                    12
                ],
                "BedPersonalRequirements":[
                    null,
                    null,
                    null
                ]
                },
                {
                "BedName":"AWR 4",
                "SubTitle":"Beatmungsplatz (wie ICU)",
                "IsVentilated":true,
                "VisibleFields":[

                ],
                "PossibleTherapies":[
                    11,
                    12
                ],
                "LockedForEventCard":false,
                "PossibleTherapiesKaep":[
                    11,
                    12
                ],
                "BedPersonalRequirements":[
                    null,
                    null,
                    null
                ]
            },
            {
                "BedName":"HNO OP",
                "SubTitle":"Beatmungsplatz (wie ICU)",
                "IsVentilated":true,
                "VisibleFields":[

                ],
                "PossibleTherapies":[
                    11,
                    12
                ],
                "LockedForEventCard":true,
                "PossibleTherapiesKaep":[
                    11,
                    12
                ],
                "BedPersonalRequirements":[
                    null,
                    null,
                    null
                ]
            }
            ],
            StationPersonalRequirements: [
                "1x jede Pflegekraft ANA",
                "1x jede Pflegekraft ANA",
                "1x Arzt ANA2"
            ],
            VisibleFields:[
            {
                "IsShared":false,
                "Personal":1,
                "FieldName":"Arzt",
                "IsOptional":true
            },
            {
                "IsShared":false,
                "Personal":1,
                "FieldName":"Pflege",
                "IsOptional":false
            }]
        },
        {
            LocationName: "NAS",
            LocationNameKaep: "Bb GELB",
            PossibleTherapies: [0, 1],
            PossibleTherapiesKaep: [2, 3, 8, 9, 10, 12],
            BedConfigs: [
            {
                "BedName":"Platz 1",
                "SubTitle":"Beatmungsplatz (wie ICU)",
                "IsVentilated":true,
                "VisibleFields":[
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":"Arzt",
                    "IsOptional":true
                },
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":"Pflege",
                    "IsOptional":true
                },
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":null,
                    "IsOptional":true
                },
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":null,
                    "IsOptional":true
                }
                ],
                "PossibleTherapies":[
                0,
                1
                ],
                "LockedForEventCard":false,
                "PossibleTherapiesKaep":[
                2,
                3,
                8,
                9,
                10,
                12
                ],
                "BedPersonalRequirements":[
                "1x jeder Arzt plus 1x jede Pflege",
                "1x jeder Arzt plus 1x jede Pflege",
                "1x Arzt ANA2 oder 1x Arzt ZNA3"
                ]
            },
            {
                "BedName":"Platz 2",
                "SubTitle":"Beatmungsplatz (wie ICU)",
                "IsVentilated":true,
                "VisibleFields":[

                ],
                "PossibleTherapies":[
                0,
                1
                ],
                "LockedForEventCard":false,
                "PossibleTherapiesKaep":[
                2,
                3,
                8,
                9,
                10,
                12
                ],
                "BedPersonalRequirements":[
                "1x jeder Arzt plus 1x jede Pflege",
                "1x jeder Arzt plus 1x jede Pflege",
                "1x Arzt ANA2 oder 1x Arzt ZNA3"
                ]
            },
            {
                "BedName":"Platz 3",
                "SubTitle":"(wie IMC)",
                "IsVentilated":false,
                "VisibleFields":[
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":"Arzt",
                    "IsOptional":true
                },
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":"Pflege",
                    "IsOptional":true
                },
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":null,
                    "IsOptional":true
                },
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":null,
                    "IsOptional":true
                }
                ],
                "PossibleTherapies":[
                0,
                1
                ],
                "LockedForEventCard":false,
                "PossibleTherapiesKaep":[
                2,
                3,
                8,
                9,
                10,
                12
                ],
                "BedPersonalRequirements":[
                "1x jeder Arzt plus 1x jede Pflege",
                "1x jeder Arzt plus 1x jede Pflege",
                "nein"
                ]
            },
            {
                "BedName":"Platz 4",
                "SubTitle":"(wie IMC)",
                "IsVentilated":false,
                "VisibleFields":[
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":"Arzt",
                    "IsOptional":true
                },
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":"Pflege",
                    "IsOptional":true
                },
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":null,
                    "IsOptional":true
                },
                {
                    "IsShared":true,
                    "Personal":37,
                    "FieldName":null,
                    "IsOptional":true
                }
                ],
                "PossibleTherapies":[
                0,
                1
                ],
                "LockedForEventCard":false,
                "PossibleTherapiesKaep":[
                2,
                3,
                8,
                9,
                10,
                12
                ],
                "BedPersonalRequirements":[
                "1x jeder Arzt plus 1x jede Pflege",
                "1x jeder Arzt plus 1x jede Pflege",
                "nein"
                ]
            }
            ],
            StationPersonalRequirements: [],
            VisibleFields:[]
        },
        {
            BedConfigs:[

            ],
            LocationName:"Bb GRÜN",
            VisibleFields:[
                {
                    "IsShared":false,
                    "Personal":4,
                    "FieldName":"Arzt ANA",
                    "IsOptional":false
                },
                {
                    "IsShared":false,
                    "Personal":4,
                    "FieldName":"Pflege ANA",
                    "IsOptional":false
                }
            ],
            LocationNameKaep:"Bb GRÜN",
            PossibleTherapies:[

            ],
            PossibleTherapiesKaep:[2, 10],
            StationPersonalRequirements:[
                "1x jede Pflegekraft",
                "1x jeder Arzt PLUS 1x jede Pflegekraft",
                "1x jede Pflegekraft"
            ]
        },
        {
            BedConfigs:[
                {
                    "BedName":"Bett 1",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Arzt",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Pflege",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Pflege",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":null,
                            "IsOptional":true
                        }
                    ],
                    "PossibleTherapies":[
                        1,
                        2,
                        3,
                        9,
                        12,
                        0
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        2,
                        3,
                        8,
                        9,
                        10
                    ],
                    "BedPersonalRequirements":[
                        "1x jeder Arzt 2 PLUS  1x jede Pflegekraft",
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Bett 2",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Arzt",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Pflege",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Pflege",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":null,
                            "IsOptional":true
                        }
                    ],
                    "PossibleTherapies":[
                        1,
                        2,
                        3,
                        9,
                        12,
                        0
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        2,
                        3,
                        8,
                        9,
                        10
                    ],
                    "BedPersonalRequirements":[
                        "1x jeder Arzt 2 PLUS  1x jede Pflegekraft",
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Bett 3",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Arzt",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Pflege",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Pflege",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":null,
                            "IsOptional":true
                        }
                    ],
                    "PossibleTherapies":[
                        1,
                        2,
                        3,
                        9,
                        12,
                        0
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        2,
                        3,
                        8,
                        9,
                        10
                    ],
                    "BedPersonalRequirements":[
                        "1x jeder Arzt 2 PLUS  1x jede Pflegekraft",
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Bett 4",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Arzt",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Pflege",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Pflege",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":null,
                            "IsOptional":true
                        }
                    ],
                    "PossibleTherapies":[
                        1,
                        2,
                        3,
                        9,
                        12,
                        0
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        2,
                        3,
                        8,
                        9,
                        10
                    ],
                    "BedPersonalRequirements":[
                        "1x jeder Arzt 2 PLUS  1x jede Pflegekraft",
                        null,
                        null
                    ]
                }
            ],
            LocationName:"Notaufnahme",
            VisibleFields:[

            ],
            LocationNameKaep:"Bb ROT/NFA",
            PossibleTherapies:[
                1,
                2,
                3,
                9,
                12,
                0
            ],
            PossibleTherapiesKaep:[
                2,
                3,
                8,
                9,
                10
            ],
            StationPersonalRequirements:[

            ]
        },
        {
            BedConfigs:[

            ],
            LocationName:"Extern",
            VisibleFields:[

            ],
            LocationNameKaep:"Extern",
            PossibleTherapies:[

            ],
            PossibleTherapiesKaep:[

            ],
            StationPersonalRequirements:[

            ]
        },
        {
            BedConfigs:[
                {
                    "BedName":"ICU 1",
                    "SubTitle":"Beatmungsplatz",
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        2,
                        3,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        3,
                        12,
                        6,
                        7,
                        8
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"ICU 2",
                    "SubTitle":"Beatmungsplatz",
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        2,
                        3,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        3,
                        12,
                        6,
                        7,
                        8
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"ICU 3",
                    "SubTitle":"Beatmungsplatz",
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        2,
                        3,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        3,
                        12,
                        6,
                        7,
                        8
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"ICU 4",
                    "SubTitle":"Beatmungsplatz",
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        2,
                        3,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        3,
                        12,
                        6,
                        7,
                        8
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"ICU 5",
                    "SubTitle":"Beatmungsplatz",
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        2,
                        3,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        3,
                        12,
                        6,
                        7,
                        8
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"ICU 6",
                    "SubTitle":"Beatmungsplatz",
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        2,
                        3,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        3,
                        12,
                        6,
                        7,
                        8
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                }
            ],
            LocationName:"ICU",
            VisibleFields:[

            ],
            LocationNameKaep:"ICU",
            PossibleTherapies:[
                2,
                3,
                12
            ],
            PossibleTherapiesKaep:[
                3,
                12,
                6,
                7,
                8
            ],
            StationPersonalRequirements:[

            ]
        },
        {
            BedConfigs:[
                {
                    "BedName":"IMC 1",
                    "SubTitle":null,
                    "IsVentilated":false,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        10,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        10,
                        12
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"IMC 2",
                    "SubTitle":null,
                    "IsVentilated":false,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        10,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        10,
                        12
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"IMC 3",
                    "SubTitle":null,
                    "IsVentilated":false,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        10,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        10,
                        12
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"IMC 4",
                    "SubTitle":null,
                    "IsVentilated":false,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        10,
                        12
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        10,
                        12
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                }
            ],
            LocationName:"IMC",
            VisibleFields:[

            ],
            LocationNameKaep:"IMC",
            PossibleTherapies:[
                10,
                12
            ],
            PossibleTherapiesKaep:[
                10,
                12
            ],
            StationPersonalRequirements:[

            ]
        },
        {
            BedConfigs:[

            ],
            LocationName:"Kühlkammer",
            VisibleFields:[

            ],
            LocationNameKaep:"Kühlkammer",
            PossibleTherapies:[

            ],
            PossibleTherapiesKaep:[

            ],
            StationPersonalRequirements:[

            ]
        },
        {
            BedConfigs:[

            ],
            LocationName:"NST",
            VisibleFields:[

            ],
            LocationNameKaep:"NST",
            PossibleTherapies:[
                2,
                10,
                12
            ],
            PossibleTherapiesKaep:[
                10,
                12
            ],
            StationPersonalRequirements:[

            ]
        },
        {
            BedConfigs:[
                {
                    "BedName":"Saal 1",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"Arzt Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Springer",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Assistent",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Operateur",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"OA Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"Pflege Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Instrumente",
                            "IsOptional":false
                        },
                        {
                            "IsShared":true,
                            "Personal":4,
                            "FieldName":null,
                            "IsOptional":true
                        },
                        {
                            "IsShared":true,
                            "Personal":15,
                            "FieldName":null,
                            "IsOptional":true
                        }
                    ],
                    "PossibleTherapies":[
                        6,
                        7
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        6,
                        7,
                        8
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Saal 2",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"Arzt Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Springer",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Assistent",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Operateur",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"OA Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"Pflege Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Instrumente",
                            "IsOptional":false
                        },
                        {
                            "IsShared":true,
                            "Personal":4,
                            "FieldName":null,
                            "IsOptional":true
                        },
                        {
                            "IsShared":true,
                            "Personal":15,
                            "FieldName":null,
                            "IsOptional":true
                        }
                    ],
                    "PossibleTherapies":[
                        6,
                        7
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        6,
                        7,
                        8
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Saal 3",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"Arzt Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Springer",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Assistent",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Operateur",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"OA Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"Pflege Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Instrumente",
                            "IsOptional":false
                        },
                        {
                            "IsShared":true,
                            "Personal":4,
                            "FieldName":null,
                            "IsOptional":true
                        },
                        {
                            "IsShared":true,
                            "Personal":15,
                            "FieldName":null,
                            "IsOptional":true
                        }
                    ],
                    "PossibleTherapies":[
                        6,
                        7
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        6,
                        7,
                        8
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                }
            ],
            LocationName:"OP",
            VisibleFields:[

            ],
            LocationNameKaep:"OP",
            PossibleTherapies:[
                6,
                7,
                8
            ],
            PossibleTherapiesKaep:[
                6,
                7,
                8
            ],
            StationPersonalRequirements:[

            ]
        },
        {
            BedConfigs:[
                {
                    "BedName":"CT",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        5
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        5
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Röntgen 1",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        4,
                        3
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        4,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Röntgen 2",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[
                        4,
                        3
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        4,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                }
            ],
            LocationName:"Radiologie",
            VisibleFields:[

            ],
            LocationNameKaep:"Radiologie",
            PossibleTherapies:[
                4,
                3,
                5
            ],
            PossibleTherapiesKaep:[
                4,
                3,
                5
            ],
            StationPersonalRequirements:[

            ]
        },
        {
            BedConfigs:[
                {
                    "BedName":"Schockraum",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[
                        {
                            "IsShared":false,
                            "Personal":4,
                            "FieldName":"Arzt Anästhesie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":15,
                            "FieldName":"Arzt Chirurgie",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":"Pflege ZNA",
                            "IsOptional":false
                        },
                        {
                            "IsShared":false,
                            "Personal":37,
                            "FieldName":null,
                            "IsOptional":true
                        }
                    ],
                    "PossibleTherapies":[
                        2,
                        3,
                        4,
                        6,
                        7,
                        9
                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        2,
                        3,
                        4,
                        6,
                        7,
                        8,
                        9
                    ],
                    "BedPersonalRequirements":[
                        "1x Arzt ANA2 plus 1x Arzt CHIR2 plus 1x Pflege ZNA",
                        null,
                        null
                    ]
                }
            ],
            LocationName:"Schockraum",
            VisibleFields:[

            ],
            LocationNameKaep:"Schockraum",
            PossibleTherapies:[
                2,
                3,
                4,
                6,
                7,
                9
            ],
            PossibleTherapiesKaep:[
                2,
                3,
                4,
                6,
                7,
                8,
                9
            ],
            StationPersonalRequirements:[
            ]
        },
        {
            BedConfigs:[
                {
                    "BedName":"Platz 1",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[

                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        0,
                        1,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Platz 2",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[

                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        0,
                        1,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Platz 3",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[

                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        0,
                        1,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Platz 4",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[

                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        0,
                        1,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Platz 5",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[

                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        0,
                        1,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Platz 6",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[

                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        0,
                        1,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Platz 7",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[

                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        0,
                        1,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                },
                {
                    "BedName":"Platz 8",
                    "SubTitle":null,
                    "IsVentilated":true,
                    "VisibleFields":[

                    ],
                    "PossibleTherapies":[

                    ],
                    "LockedForEventCard":false,
                    "PossibleTherapiesKaep":[
                        0,
                        1,
                        3
                    ],
                    "BedPersonalRequirements":[
                        null,
                        null,
                        null
                    ]
                }
            ],
            LocationName:"Sichtungsplatz",
            VisibleFields:[

            ],
            LocationNameKaep:"Sichtungsplatz",
            PossibleTherapies:[

            ],
            PossibleTherapiesKaep:[
                0,
                1,
                3
            ],
            StationPersonalRequirements:[

            ]
        },
        {
            BedConfigs:[

            ],
            LocationName:"vor dem Krankenhaus",
            VisibleFields:[
            ],
            LocationNameKaep:"vor dem Krankenhaus",
            PossibleTherapies:[

            ],
            PossibleTherapiesKaep:[

            ],
            StationPersonalRequirements:[

            ]
        }
    ],
    "Layoutid" : ""
}
