<template>
    <div v-if="selectedPatient" class="wrapper">
        <div class="group">
            <h3>Ausgewählter Patient</h3>

            <div class="patient">
                <div><b>PatientId:</b> {{this.selectedPatient.patientid}}</div>
                <div><b>SK:</b> {{this.selectedPatient.sk}}</div>
                <div><b>SK-Begründung:</b> {{this.selectedPatient.sk_reason}}</div>
                <div><b>SK-Zeit:</b> {{this.selectedPatient.sk_action - this.selectedPatient.arrived}} Sekunden</div>
                <div><b>SK-Ort:</b> {{this.selectedPatient.sk_loc}}</div>
                <div><b>eFast:</b> {{this.selectedPatient.efast == 0 ? "Nein" : "Ja"}}</div>
                <div><b>Gegenwärtiger Ort:</b> {{this.selectedPatient.ggwloc}}</div>
                <div><b>Lebenspunkte:</b> {{this.selectedPatient.lp}}</div>
                <div><b>Score:</b> {{this.selectedPatient.score}}</div>
                <div><b>Warterunden:</b> {{this.selectedPatient.waiting_rounds}}</div>
            </div>

            <div class="history">

                <h3>Historie</h3>
                <div v-for="(content, group) of scenarioHistory">
                    <div v-if="selectedGroup == group">
                        <div v-for="entry of content">
                            <div v-if="entry.PID == selectedPatient.patientid" class="historywrapper">
                                <div class="entry"> <b>Step:</b>  {{entry.S}}</div>
                                <div v-html="entry.M" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="optionholder">
                <div class="optionwrapper">
                    <div v-for="category of categories_top" :key="category.key" class="optionwrapper_entry">
                        <Checkbox style="margin-right: 10px" v-model="selectedCategories" :inputId="category.key" name="category" :value="category.key" />
                        <label :for="category.key">{{ category.name }}</label>
                    </div>
                </div>
                <div class="optionwrapper">
                    <div v-for="category of categories_bot" :key="category.key" class="optionwrapper_entry">
                        <Checkbox style="margin-right: 10px" v-model="selectedCategories" :inputId="category.key" name="category" :value="category.key" />
                        <label :for="category.key">{{ category.name }}</label>
                    </div>
                </div>
            </div>
            <h3 style="padding-top: 20px">Gruppenhistorie {{selectedGroup}}</h3>
            <div v-for="(content, group) of scenarioHistory" class="history">
                <div v-if="selectedGroup == group">
                    <div v-for="entry of content">
                        <div v-if="this.selectedCategories.includes(entry.X)">
                            <div v-if="entry.PID == selectedPatient.patientid" class="historywrapper">
                                <div class="entry_red"> <b>Step:</b>  {{entry.S}}</div>
                                <div v-html="entry.M" />
                            </div>
                            <div v-else class="historywrapper">
                                <div class="entry"> <b>Step:</b>  {{entry.S}}</div>
                                <div v-html="entry.M" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["scenarioHistory", "selectedPatient", "selectedGroup"],
    data() {
        return {
            checked: false,
            categories_top: [
                {name: "Behandlung", key: "---"},
                {name: "Patientenwerte (System)", key: "!!!"},
                {name: "Patientenwerte (Karte)", key: ">>>"},
                {name: "Neue Runde", key: "==="},
            ],
            categories_bot: [
                {name: "Checkbox", key: "[X]"},
                {name: "Knopf", key: "+++"},
                {name: "SK", key: "SK:"},
                {name: "Ereigniskarte", key: "***"},
            ],
            selectedCategories: ["---", "!!!", ">>>", "===", "[X]", "+++", "SK:", "***", ""],
        }
    }
}
</script>

<style scoped>

.optionwrapper {
    display: flex;
    align-content: center;
    justify-content: center;
}

.optionholder {
    border-width: 1px;
    margin-top: 20px;
}

.optionwrapper_entry {
    display: flex;
    margin: 20px;
}

.historywrapper {
    display: flex;
}

.history {
    margin-top: 20px;
}

.patient {
    margin-top: 20px
}

.entry {
    margin-right: 30px;
}
.entry_red {
    margin-right: 30px;
    color: red;
}

.group {
    margin-right: 100px;
    min-width: 550px;
}

.wrapper {
    display: flex;
}

</style>
