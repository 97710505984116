<template>
    <div v-for="(bed, bedIndex) of this.BedConfigs" class="bett-box">
        <Fieldset :toggleable="true" :collapsed="bedIndex !== this.BedConfigs.length -1">
            <template #legend>
                {{bed.BedName}}
            </template>
            <table>
                <tr>
                    <td>
                        <div>
                            <p v-tooltip.right="'Der angezeigte Titel auf dem Bett'">Bettenname:</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <InputText type="text" class="p-inputtext-sm" v-model="this.BedConfigs[bedIndex].BedName" />
                        </div>
                    </td>
                    <td>
                        <div>
                            <Button icon="pi pi-trash" class="p-button-rounded" @click="removeBed(bedIndex)"/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <p v-tooltip.right="'Der angezeigte Untertitel auf dem Bett'">Bettenuntertitel:</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <InputText type="text" class="p-inputtext-sm" v-model="this.BedConfigs[bedIndex].SubTitle" />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <p v-tooltip.right="'Legt fest, ob das Bett beatmete Patienten versorgen kann'">Beatmungsplatz:</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <Checkbox v-model="this.BedConfigs[bedIndex].IsVentilated" :binary="true" />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <p v-tooltip.right="'Gesperrtes Feld bis es von einer Eventkarte entsperrt wird'">Für Eventkarte gesperrt:</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <Checkbox v-model="this.BedConfigs[bedIndex].LockedForEventCard" :binary="true" />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="padding-top: 15px;"><p v-tooltip.right="'Die auf diesem Bett möglichen Therapien im Normalbetrieb'"><u>Therapien in Normal-Betrieb:</u></p></td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <div v-for="(therapie, tindex) of this.BedConfigs[bedIndex].PossibleTherapies" key="index" class="therapie-entry">
                            <Dropdown v-model="this.BedConfigs[bedIndex].PossibleTherapies[tindex]" :options="this.therapies" optionLabel="name" optionValue="code" placeholder="Therapie auswählen" />
                        </div>
                        <div v-if="this.BedConfigs[bedIndex].PossibleTherapies.length === 0">
                            <p class="empty-text">Keine Therapien angelegt!</p>
                        </div>
                        <div class="btn2">
                            <Button icon="pi pi-plus" class="p-button-rounded" @click="addTherapieToNormalBetieb(bedIndex)"/>
                            <Button icon="pi pi-minus" class="p-button-rounded" @click="removeTherapieFromNormalBetrieb(bedIndex)"/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><p v-tooltip.right="'Die auf diesem Bett möglichen Therapien im KAEPBetrieb'"><u>Therapien in KAEP-Betrieb:</u></p></td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <div v-for="(therapie, jindex) of this.BedConfigs[bedIndex].PossibleTherapiesKaep" key="index" class="therapie-entry">
                            <Dropdown v-model="this.BedConfigs[bedIndex].PossibleTherapiesKaep[jindex]" :options="this.therapies" optionLabel="name" optionValue="code" placeholder="Therapie auswählen" />
                        </div>
                        <div v-if="this.BedConfigs[bedIndex].PossibleTherapiesKaep.length === 0">
                            <p class="empty-text">Keine Therapien angelegt!</p>
                        </div>
                        <div class="btn2">
                            <Button icon="pi pi-plus" class="p-button-rounded" @click="addTherapieToKAEPBetieb(bedIndex)"/>
                            <Button icon="pi pi-minus" class="p-button-rounded" @click="removeTherapieFromKAEPBetrieb(bedIndex)"/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="padding-top: 30px;">
                        <div>
                            <p><u>Personalanforderungen:</u></p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <p v-tooltip.right="'Anforderung um einen nicht beatmeten Patienten auf diesem Bett versorgen zu können'">Nicht beatmet Versorgung</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <Textarea type="text" class="p-inputtext-sm" v-model="this.BedConfigs[bedIndex].BedPersonalRequirements[0]" auto-resize="true" rows="2" cols="40" />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <p v-tooltip.right="'Anforderung um einen Patienten in dieses Bett verlegen zu können'">Verlegung</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <Textarea type="text" class="p-inputtext-sm" v-model="this.BedConfigs[bedIndex].BedPersonalRequirements[1]" auto-resize="true" rows="2" cols="40"/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <p v-tooltip.right="'Anforderung um einen beatmeten Patienten auf diesem Bett versorgen zu können'">Beatmet versorgen</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <Textarea type="text" class="p-inputtext-sm" v-model="this.BedConfigs[bedIndex].BedPersonalRequirements[2]" auto-resize="true" rows="2" cols="40"/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="padding-top: 30px;">
                        <p><u>Sichtbare PersonalFelder:</u></p>
                    </td>
                </tr>
                <tr>
                    <td>

                    </td>
                    <td style="padding-top: 30px;">
                        <div style="padding-left: 100px" v-if="this.BedConfigs[bedIndex].VisibleFields.length === 0">
                            <p class="empty-text">Keine Personenfelder angelegt!</p>
                        </div>
                        <ScenarioPersonalField :VisibleFields="this.BedConfigs[bedIndex].VisibleFields"></ScenarioPersonalField>
                    </td>
                </tr>
            </table>
        </Fieldset>
    </div>
    <div class="btn">
        <Button icon="pi pi-plus" class="p-button-rounded" @click="addBed()"/>
    </div>
</template>

<script>
import values from '../tdsc-module'
import bedConfig from "../Models/bedConfig";

import ScenarioPersonalField from "./hospitalPersonalField";

export default {
    components: {ScenarioPersonalField},
    props: ["BedConfigs"],
    data() {
        return  {
            therapies: values.therapies,
            bedModel: bedConfig,
        }
    },
    methods: {
        // add a therapie to a bed in normal
        addTherapieToNormalBetieb: function (bedIndex) {
            this.BedConfigs[bedIndex].PossibleTherapies.push("");
        },
        // remove a therapie from a bed in normal
        removeTherapieFromNormalBetrieb: function (bedIndex) {
            this.BedConfigs[bedIndex].PossibleTherapies.pop();
        },
        // add a therapie to a bed in kaep
        addTherapieToKAEPBetieb: function (bedIndex) {
            this.BedConfigs[bedIndex].PossibleTherapiesKaep.push("");
        },
        // remove a therapie from a bed in normal
        removeTherapieFromKAEPBetrieb: function (bedIndex) {
            this.BedConfigs[bedIndex].PossibleTherapiesKaep.pop();
        },
        // clone a bedmodel and add it to the bed collection
        addBed: function () {
            let bedModel = JSON.parse(JSON.stringify(this.bedModel));
            bedModel.BedName = "Bett " + (this.BedConfigs.length +1);
            this.BedConfigs.push(bedModel);
        },
        // remove a bedmodel from the bed collection
        removeBed: function(bedIndex) {
            this.BedConfigs.splice(bedIndex, 1);
        }}
}
</script>

<style>
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0 !important;

}
.bett-box {
    min-width: 700px;
}

.btn2 {
    padding: 5px;
    padding-left: 50px;
    padding-top: 30px;
}
.btn {
    padding: 5px;
    padding-left: 300px;
    padding-top: 30px;
}

.btn2 Button {
    margin-right: 10px;
}
td {
    padding-right: 5px;
}

.therapie-entry{
    padding: 2px;
}
td div p {
    padding-top: 13px
}

.empty-text {
    font-size: 15px;
    color: #8a9199;
}

</style>
