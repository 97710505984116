/**
 * This model defines a round in scenarioGenerals.
 */
export default {
    Round: 1,
    EventText: [],
    Patients: [],
    Functions: [],
    Parameter: [],
}
