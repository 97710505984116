<template>
    <div class="empty-text" v-if="this.Rounds.length === 0">
        <p>Keine Runden angelegt</p>
    </div>
    <div v-for="(round, index) of this.Rounds" style="padding-bottom: 10px">
        <Fieldset :toggleable="true" :collapsed="true">
            <template #legend>
                {{ round.Round }}
            </template>
            <tr>
                <td>
                    <p>Runde:</p>
                </td>
                <td>
                    <InputText type="number" min="1" class="p-inputtext-sm" v-model="this.Rounds[index].Round" />
                </td>
                <td>
                    <div style="width: 255px;">
                        <Button icon="pi pi-trash" class="p-button-rounded" style="float: right;" @click="removeRoundEntry(index)"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Ereignistext:</p>
                </td>
                <td>
                    <div v-for="(item, rindex) of this.Rounds[index].EventText">
                        <Textarea v-model="this.Rounds[index].EventText[rindex]" auto-resize="true" rows="3" cols="55"/>
                    </div>
                    <div v-if="this.Rounds[index].EventText === 0">
                        <p class="empty-text">Keine EventTexte angelegt!</p>
                    </div>
                    <div class="btn">
                        <Button icon="pi pi-plus" class="p-button-rounded" @click="addIntroText(index)"/>
                        <Button icon="pi pi-minus" class="p-button-rounded" @click="removeIntroText(index)"/>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    <p>Eintreffende Patienten:</p>
                </td>
                <td>
                    <MultiSelect v-model="this.Rounds[index].Patients" :options="this.patients" optionLabel="name" placeholder="Patienten wählen" />
                </td>
            </tr>
            <tr>
                <td>
                    <p v-tooltip.right="'DeleteRedStones löscht alle roten Steine. Übergabeparameter zb. AWR, Sichtungsplatz, ... Ohne Parameter werden alle Locations verwendet.\n  ' +
                     'DeleteRedStonesCount löscht eine gegebene Anzahl an Steinen zb. AWR, 1\n' +
                      'SetRedStones setzt rote Steine. Übergabeparameter sind Location, anzahl an Betten, Zeit - zb. AWR,2,1'">Auszuführende Funktionen:</p>
                </td>
            </tr>

            <tr v-for="(fkt, findex) of this.Rounds[index].Functions">
                <td></td>
                <td>
                    <Dropdown v-model="this.Rounds[index].Functions[findex]" :options="this.functions" optionLabel="name" optionValue="code" placeholder="Funktion auswählen" />
                    (<InputText style="padding-left: 10px" type="text" v-model="this.Rounds[index].Parameter[findex]" />)
                </td>
            </tr>

            <div class="empty-text" v-if="this.Rounds[index].Functions.length === 0">
                <p>Keine Funktionen angelegt</p>
            </div>
            <div class="btn">
                <Button icon="pi pi-plus" class="p-button-rounded" @click="addFunctionEntry(index)"/>
                <Button icon="pi pi-minus" class="p-button-rounded" @click="removeFunctionEntry(index)"/>
            </div>
        </Fieldset>

    </div>
    <div class="btn">
        <Button icon="pi pi-plus" class="p-button-rounded" @click="addRoundEntry"/>
    </div>

</template>

<script>
import ScenarioRoundEntry from '../Models/roundModel'
import values from '../tdsc-module'
export default {
    props: ['Rounds'],
    data() {
        return {
            roundModel: ScenarioRoundEntry,
            patients: values.patients,
            functions: values.functions,
            values: values
        }
    },
    methods: {
        // adds a round entry
        addRoundEntry: function () {
            let roundModel = JSON.parse(JSON.stringify(this.roundModel));
            this.Rounds.push(roundModel);
        },
        // removes a round entry
        removeRoundEntry: function(index) {
            this.Rounds.splice(index, 1);
        },
        // adds a function string
        addFunctionEntry: function (index) {
            this.Rounds[index].Functions.push("");
            this.Rounds[index].Parameter.push({"first" : ""});
        },
        // removes a function string
        removeFunctionEntry: function (index) {
            this.Rounds[index].Functions.pop();
            this.Rounds[index].Parameter.pop();
        },
        // add an eventtext
        addIntroText: function (index) {
            this.Rounds[index].EventText.push("");
        },
        // remove an eventtext
        removeIntroText: function(index) {
            this.Rounds[index].EventText.pop();
        },
    }
}
</script>

<style scoped>
.btn {
    padding-left: 315px;
}

.btn Button {
    margin-right: 10px;
}
.empty-text {
    font-size: 15px;
    color: #8a9199;
    padding-left:300px;
}

td {
    vertical-align: top;
}

.functionwrapper {
    display: flex;
}

.functionentry {
    padding-left: 25px;
}
</style>
