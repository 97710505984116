<template>
     <div>
        <div v-if="!this.isLoading">
            <div>
                <div class="row">
                    <h4>Sessions</h4>
                    <Divider align="right">
                        <Dropdown style="margin-right: 20px" v-model="this.selectedSessionKey" :options="this.sessionKeys" optionLabel="name" :filter="true" placeholder="Select a Session" @change="this.verifySession"/>
                        <Button style="margin-right: 10px" label="Session erstellen" icon="pi pi-key" class="p-button-warning"  @click="toggle"></Button>
                        <Button v-if="isadmin" style="margin-right: 10px" icon="pi pi-trash" class="p-button-warning"  @click="deleteSession"></Button>
                    </Divider>
                    <OverlayPanel ref="op">
                        <InputText type="text" placeholder="Sessionkey eintragen" v-model="this.inputsessionid" />
                        <Button style="margin-left: 10px" label="Speichern"  class="p-button-success"  @click="createSessionId"></Button>
                    </OverlayPanel>
                </div>
                <div v-if="this.sessionKeys.length == 0">
                    <p class="loading">Keine Session vorhanden!</p>
                </div>
                <div v-else>
                    <div>
                        <div class="head">
                            <SelectButton v-model="selectedView" :options="this.Helper.viewOptions" optionLabel="name" :multiple="false" @click="polling"/>
                                <div style="padding-left: 20px" v-if="this.selectedView.code !== 'progress'">
                                    <Button icon="pi pi-refresh" rounded aria-label="Filter" @click="refreshData" />
                                </div>
                                <div style="padding-left: 20px" v-else>
                                    <Button icon="pi pi-refresh" rounded aria-label="Filter" @click="loadProgress" />
                                </div>
                        </div>
                        <div style="padding-bottom: 10px; display:flex" v-if="this.selectedView.code !== 'progress'">
                            <SelectButton v-model="selectedLayoutOption" :options="layoutOptions" dataKey="value" style="margin-left: 10px">
                                <template #option="slotProps">
                                    <i :class="slotProps.option.icon"></i>
                                </template>
                            </SelectButton>
                            <SelectButton v-if="this.selectedView.code === 'ther' || this.selectedView.code === 'therort'" v-model="selectedOption" :options="options" dataKey="value" style="margin-left: 10px">
                                <template #option="slotProps">
                                    <i :class="slotProps.option.icon"></i>
                                </template>
                            </SelectButton>

                        </div>

                    </div>
                    <div v-if="Object.values(this.scenarioHistory).length > 0">
                        <b>Szenario: </b><span>{{Object.values(this.scenarioHistory)[0][0]["M"].replace(" gestartet!", "").replace("Scenario: ", "") }}</span>
                        <b style="padding-left: 40px">Sessionstart: </b><span>{{Object.values(this.sessionInfo)[0]["created_at"].match(/\d{4}-\d{2}-\d{2}/)[0] }} um {{Object.values(this.sessionInfo)[0]["created_at"].match(/\d{2}:\d{2}:\d{2}/)[0]}} Uhr</span>
                        <b style="padding-left: 40px">Sessionende: </b><span>{{Object.values(Object.values(this.groupresults)[0])[0]["created_at"].match(/\d{4}-\d{2}-\d{2}/)[0] }} um {{Object.values(Object.values(this.groupresults)[0])[0]["created_at"].match(/\d{2}:\d{2}:\d{2}/)[0]}} Uhr</span>

                    </div>
                    <div v-if="this.selectedView.code === 'sk'">
                        <TextOverview :data="this.Helper.initSkText(this.groupresults)" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        <PieChartOverview :data="this.Helper.initSkGraph(this.groupresults)" v-if="selectedLayoutOption.value === 2"></PieChartOverview>
                    </div>
                    <div v-if="this.selectedView.code === 'lp'">
                        <TextOverview :data="this.Helper.initLPText(this.groupresults)" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        <BarChartOverview :data="this.Helper.initLPGraph(this.groupresults)" :group="false" v-if="selectedLayoutOption.value === 2"></BarChartOverview>
                    </div>
                    <div v-if="this.selectedView.code === 'diag'">
                        <TextOverview :data="this.Helper.initDiagnoseText(this.groupresults)" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        <PieChartOverview :data="this.Helper.initDiagnoseGraph(this.groupresults)" v-if="selectedLayoutOption.value === 2"></PieChartOverview>
                    </div>
                    <div v-if="this.selectedView.code === 'ther'">
                        <div v-if="selectedOption.value === 1">
                            <TextOverview :data="this.Helper.initTherapieStepsText(this.groupresults)" :group="false" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        </div>
                        <div v-else>
                            <TextOverview :data="this.Helper.initTherapieStepsGroupsText(this.groupresults)" :group="true" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        </div>
                        <div v-if="selectedOption.value === 1">
                            <BarChartOverview :data="this.Helper.initTherapieStepsGraph(this.groupresults)" :group="false" v-if="selectedLayoutOption.value === 2"></BarChartOverview>
                        </div>
                        <div v-else>
                            <BarChartOverview :data="this.Helper.initTherapieStepsGroupsGraph(this.groupresults)" :group="true" v-if="selectedLayoutOption.value === 2"></BarChartOverview>
                        </div>
                    </div>
                    <div v-if="this.selectedView.code === 'therort'">
                        <div v-if="selectedOption.value === 1">
                            <TextOverview :data="this.Helper.initLocationText(this.groupresults)" :group="false" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        </div>
                        <div v-else>
                            <TextOverview :data="this.Helper.initLocationGroupText(this.groupresults)" :group="true" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        </div>
                        <div v-if="selectedOption.value === 1">
                            <BarChartOverview :data="this.Helper.initLocationGraph(this.groupresults)" :group="false" v-if="selectedLayoutOption.value === 2"></BarChartOverview>
                        </div>
                        <div v-else>
                            <BarChartOverview :data="this.Helper.initLocationGroupGraph(this.groupresults)" :group="true" v-if="selectedLayoutOption.value === 2"></BarChartOverview>
                        </div>
                    </div>
                    <div v-if="this.selectedView.code === 'stather'">
                        <TextOverview :data="this.Helper.initStatusText(this.groupresults)" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        <BarChartOverview :data="this.Helper.initStatusGraph(this.groupresults)" :group="false" v-if="selectedLayoutOption.value === 2"></BarChartOverview>
                    </div>
                    <div v-if="this.selectedView.code === 'perf'">
                        <TextOverview :data="this.Helper.initPerformanceText(this.groupresults)" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        <BarChartOverview :data="this.Helper.initPerformanceGraph(this.groupresults)" :group="true" v-if="selectedLayoutOption.value === 2"></BarChartOverview>
                    </div>
                    <div v-if="this.selectedView.code === 'beds'">
                        <TextOverview :data="this.Helper.initBedCoverText(this.groupresults)" v-if="selectedLayoutOption.value === 1"></TextOverview>
                        <BarChartOverview :data="this.Helper.initBedCoverGraph(this.groupresults)" :group="true" v-if="selectedLayoutOption.value === 2"></BarChartOverview>
                    </div>
                    <div v-if="this.selectedView.code === 'progress'">
                        <table class="table table-bordered sticky-header-table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="row">Gruppe</th>
                                    <th scope="row">Runde</th>
                                    <th scope="row">Spielphase</th>
                                </tr>
                            </thead>
                            <tr v-for="entry of this.scenarioProgress">
                                <td>{{entry.groupid}}</td>
                                <td>{{entry.round}}</td>
                                <td>{{this.Helper.intToGameState(entry.gamestate)}}</td>
                            </tr>
                        </table>
                    </div>
                    <div v-if="this.selectedView.code !== 'progress'">
                        <History :scenarioHistory="scenarioHistory" :selectedPatient="selectedPatient" :selectedGroup="selectedPatientGroup">

                        </History>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading" v-else>
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
        </div>
    </div>
</template>

<script>
import TextOverview from "./textOverview";
import BarChartOverview from "./barChartOverview";
import PieChartOverview from "./pieChartOverview";
import History from "./history";
import Helper from "../session-module"
export default {
    components: {History, TextOverview, BarChartOverview, PieChartOverview},
    data() {
        return {
            // currently visible view cathegorie
            selectedView: {name: 'Progress', code: 'progress'},
            // sessionkey selected in ui
            selectedSessionKey: null,
            // sessionkey that is loaded and shown. This is important to determine if the user selected another session and data needs to be reloaded
            loadedSessionKey: "",
            isLoading: true,
            // list with all sessionkeys. This get filled by a getrequest in the mounted callback and filled in getSessionKeys.
            sessionKeys: [],
            // the data of the session von loadedSessionKey. This get filled by a getrequest in the verifySession method.
            groupresults: null,
            // step by step action history
            scenarioHistory: null,
            // infos about the session itself
            sessionInfo: null,
            // this tells how the data is presented. In textform or graph
            selectedLayoutOption: {icon: '', value: 1},
            // all present options
            layoutOptions: [
                {icon: 'pi pi-table', value: 1},
                {icon: 'pi pi-chart-pie', value: 2}],
            Helper,
            inputsessionid: "",
            selectedOption: {icon: '', value: 1},
            options: [
                {icon: 'pi pi-user', value: 1},
                {icon: 'pi pi-users', value: 2}
            ],
            selectedPatient: null,
            selectedPatientGroup: null,
            scenarioProgress: null,
            pollingEvent: null
        }
    },
    mounted() {
        this.isLoading = true;
        // app is created. Init
        this.getSessionKeys();
        this.polling();
    },
    methods : {
        // refreshbutton
        refreshData: function() {
            this.isLoading = true;
            this.loadSession();
        },
        // ask the database for all sessionkeys
        getSessionKeys: function() {
            axios
                .get(window.location.href + 'api/codebook/sessionids')
                .then(response => (this.getSessionKeysResponse(response)));
        },
        // get all sessionkeys from server and init the first one.
        getSessionKeysResponse: function(response) {
            this.sessionKeys = [];

            for(const key of response["data"]) {
                this.sessionKeys.push({name : key, code: key});
            }
            if (this.sessionKeys.length > 0) {
                this.selectedSessionKey = this.sessionKeys[0];
                this.verifySession();
            }
            else {
                this.isLoading = false;
            }
        },
        // load the session with key = selectedSessionKey
        loadSession: function() {
            this.groupresults = null;
            this.loadedSessionKey = this.selectedSessionKey;
            axios
                .get(window.location.href + 'api/codebook/groupresults?sessionid=' + this.selectedSessionKey.name)
                .then(response => (this.loadSessionResponse(response)))

        },
        deleteSession: function() {
            this.$confirm.require({
                message: 'Session \"' + this.selectedSessionKey.name + '\" und alle daran gebundene Daten unwiderruflich löschen?',
                header: 'Löschen',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    axios
                        .get(window.location.href + 'api/codebook/deletesession?sessionid=' + this.selectedSessionKey.name)
                        .then(response => (this.deleteSessionResponse(response)))
                },
                reject: () => {

                }
            });
        },

        deleteSessionResponse: function(response) {
            this.isLoading = true;
            // app is created. Init
            this.getSessionKeys();
        },

        // get the maindata from the server
        loadSessionResponse: function(response) {
            this.groupresults = response.data["groupresults"];
            this.scenarioHistory = response.data["grouphistories"];
            this.sessionInfo = response.data["sessioninfo"];
            this.isLoading = false;
            this.loadProgress();
        },
        // look if a different key is selected and trigger reload if so
        verifySession: function () {
            if (this.selectedSessionKey !== this.loadedSessionKey){
                this.isLoading = true;
                this.loadSession();
                return true;
            }
        },
        createSessionId: function() {
            axios
                .get(window.location.href + 'api/codebook/createsessionid?sessionid=' + this.inputsessionid)
                .then(response => (this.createSessionKeyResponse(response)))
        },
        createSessionKeyResponse: function(response) {
            switch (response["data"]["ResponseCode"]) {
                case 200:
                    this.getSessionKeys();
                    this.$toast.add({severity:'success', summary: 'Session erstellt!', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
                case 409:
                    this.$toast.add({severity:'error', summary: 'Fehler', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
            }
        },
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        loadPatientWithId: function(patientid, groupid) {
            if (!isNaN(parseInt(groupid))) {
                let grpid = groupid;
                groupid = patientid;
                patientid = grpid;
            }

            if (this.selectedPatient != null && this.selectedPatient.patientid === patientid &&
            this.selectedPatientGroup != null && this.selectedPatientGroup === groupid) {
                this.selectedPatient = null;
                this.selectedPatientGroup = null;
                return;
            }

            this.selectedPatientGroup = groupid;
            axios
                .get(window.location.href + 'api/codebook/getpatient?sessionkey=' + this.selectedSessionKey.name + "&groupid=" + groupid +"&patientid=" + patientid)
                .then(response => (this.loadPatientWithIdResponse(response)))
        },
        loadPatientWithIdResponse: function(response) {
            this.selectedPatient = response.data;
        },
        loadProgress: function() {
            axios
                .get(window.location.href + 'api/codebook/scenarioProgress?sessionid=' + this.selectedSessionKey.name)
                .then(response => (this.loadProgressResponse(response)))
        },
        loadProgressResponse: function(response) {
            this.scenarioProgress = response.data;
        },
        polling: function() {
            if (this.selectedView.code === 'progress') {
                this.pollingEvent = setInterval(this.loadProgress, 5000);
            }

            if (this.selectedView.code !== 'progress' && this.pollingEvent !== null) {
                clearInterval(this.pollingEvent);
            }
        }
    },
    computed: {
        isadmin: function () {
            return sessionStorage.getItem('role') === 'admin'
        }
    }
}
</script>

<style scoped>
.head {
    display:inline-flex;
    padding-bottom: 10px;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
}
.view-header {
    padding-bottom: 10px;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
.history {
    padding-top: 10px;
    padding-left: 20px;
}

.history span {
    padding-right: 30px;
}

.sticky-header-table {
    width: 100%;
    border-collapse: collapse;
}

/* Styling für den klebrigen Tabellenkopf */
.sticky-header-table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>
