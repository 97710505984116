<template>
    <div class="personalfield-box">
        <Fieldset v-for="(personalField, index) of this.VisibleFields" :toggleable="true" :collapsed="true">
            <template #legend>
                {{personalField.FieldName}}
            </template>
            <tr>
                <td>
                    <p v-tooltip.right="'Der angezeigte Name unter dem Feld'">PersonalFieldname: </p>
                </td>
                <td>
                    <div>
                        <InputText type="text" class="p-inputtext-sm" v-model="this.VisibleFields[index].FieldName"  />
                    </div>
                </td>
                <td>
                    <div>
                        <Button icon="pi pi-trash" class="p-button-rounded" @click="removePersonalField(index)"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <p v-tooltip.right="'Rein optisch. Legt die Umrandung des Feldes fest'">Optionales Feld: </p>
                </td>
                <td>
                    <div>
                        <Checkbox v-model="this.VisibleFields[index].IsOptional" :binary="true" />
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <p v-tooltip.right="'Geteilte Felder ermöglichen es, Spielfiguren zwischen 2 Betten zu teilen.'">Geteiltes Feld: </p>
                </td>
                <td>
                    <div>
                        <Checkbox v-model="this.VisibleFields[index].IsShared" :binary="true"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <p v-tooltip.right="'Rein optisch. Legt die Farbe des Feldes fest'">Personalart: </p>
                </td>
                    <td>
                        <Dropdown v-model="this.VisibleFields[index].Personal" :options="this.personalKinds" optionLabel="name" optionValue="code" placeholder="Personal wählen"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p v-tooltip.right="'Welche Person ist zu Beginn auf diesem Feld'">Person: </p>
                    </td>
                <td>
                    <Dropdown v-model="this.VisibleFields[index].Person" :options="this.codebookPersons" optionLabel="name" optionValue="code" placeholder="Personal wählen"/>
                </td>
            </tr>
        </Fieldset>
    </div>
    <div class="personalfield-btn">
        <Button icon="pi pi-plus" class="p-button-rounded" @click="addPersonalField()"/>
    </div>
</template>

<script>
import personField from "../Models/personField";
import values from '../tdsc-module'

export default {
    props:["VisibleFields"],
    data() {
        return  {
            personModel: personField,
            personalKinds: values.codebookPersonal,
            codebookPersons: values.codebookPersons,
        }
    },
    methods: {
        // adds a personal entry
        addPersonalField: function() {
            let personalField = JSON.parse(JSON.stringify(this.personModel));
            personalField.FieldName = "PersonalField " + (this.VisibleFields.length + 1);
            this.VisibleFields.push(personalField);
        },
        // removes a personal entry
        removePersonalField: function(index) {
            this.VisibleFields.splice(index, 1);
        }
    }
}
</script>

<style scoped>

.personalfield-box {
    min-width: 380px;
}
.personalfield-btn {
    padding-top: 30px;
    padding-left: 180px;
}
</style>
