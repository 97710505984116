<template>
    <div class="row">
        <h4>Intern</h4>
        <Divider align="right">
        </Divider>
    </div>
    <div style="padding-top: 15px">
        <SelectButton v-model="this.selected" :options="this.menupoints" optionLabel="name" :multiple="false" @change=this.showContent />
        <div v-if="!this.isLoading" class="reports">
            <div v-for="report of this.reports" class="entry">
                <div v-if="this.selected.code === report.type">
                    <Card style="max-width: 1050px">
                    <template #title>
                        <div class="cardTitle"><p> {{report.created_at.substring(0, 10)}}   {{report.created_at.substring(11, 16)}} Uhr </p>
                            <div style="margin-left: auto;" v-if="this.selected.code === 0">
                                <Button style="margin-left: auto; margin-right: 10px" @click="editReport(report.id, 3)">Move to Archiv</Button><Button style="margin-left: auto;" @click="editReport(report.id, 2)">Move to Test</Button>
                            </div>
                            <div style="margin-left: auto;" v-if="this.selected.code === 1">
                                <Button @click="editReport(report.id, 2)" >Move to Test</Button>
                            </div>
                            <div style="margin-left: auto;" v-if="this.selected.code === 2">
                                <Button style="margin-left: auto; margin-right: 10px" @click="editReport(report.id, 0)">Move to Bug</Button><Button style="margin-left: auto;" @click="editReport(report.id, 1)">Move to Feedback</Button>
                            </div>
                            <div style="margin-left: auto;" v-if="this.selected.code === 3">
                                <Button style="margin-left: auto; margin-right: 10px" @click="editReport(report.id, 0)">Move to Bug</Button><Button style="margin-left: auto;" @click="editReport(report.id, 2)">Move to Test</Button>
                            </div>
                            <Button style="margin-left: 10px; width: 40px; height: 40px" icon="pi pi-trash" class="p-button-warning"  @click="deleteReport(report.id)"></Button>
                        </div>
                        <p style="font-size: 15px">SessionId: <b>Report</b></p>
                        <p style="font-size: 15px">GruppenId: <b>{{report.id}}</b></p>

                    </template>
                    <template #content>
                        <p>{{report.user_msg}}</p>
                    </template>
                    <template #footer>
                       <div class="entryFooter">
                        <div class ="msgs">
                            <Accordion>
                                <AccordionTab header="Error message">
                                    {{report.error_code}}
                                </AccordionTab>
                                <AccordionTab header="Stacktrace">
                                    {{report.stack_trace}}
                                </AccordionTab>
                            </Accordion>
                        </div>
                       </div>
                    </template>
                </Card>
                </div>
            </div>
        </div>
        <div class="loading" v-else>
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            reports: null,
            isLoading: true,
            selected: null,
            menupoints: [
                {name: 'Bug', code: 0},
                {name: 'Bugarchiv', code: 3},
                {name: 'Feedback', code: 1},
                {name: 'Test', code: 2}
            ]
        }
    },
    mounted() {
        this.selected = {name: 'Bug', code: 0};
        this.showContent();
    },
    methods: {
        showContent: function () {
            this.isLoading = true;
            this.getReports();
        },
        // ask for bugreports
        getReports: function () {
            axios
                .get(window.location.href + 'api/codebook/getreports')
                .then(response => (this.getReportsResponse(response)));
        },
        // get the bugreports
        getReportsResponse: function (response) {
            this.reports = response.data;
            this.isLoading = false;
        },

        deleteReport: function(id) {
            this.$confirm.require({
                message: 'Report \"' + id + '\" und alle daran gebundene Daten unwiderruflich löschen?',
                header: 'Löschen',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    axios
                        .get(window.location.href + 'api/codebook/deletereport?id=' +id)
                        .then(response => (this.deleteReportResponse(response)))
                },
                reject: () => {

                }
            });
        },
        deleteReportResponse(response) {
            this.showContent();
        },
        // type tells what kind of report it should be. 0 = Bug, 1 = Feedback, 2 = Test, 3 = BugArchiv
        editReport: function (id, type) {
            axios
                .get(window.location.href + 'api/codebook/editreport?id=' + id + "&updateType=" + type)
                .then(response => (this.editReportResponse(response)));
        },
        editReportResponse: function(response) {
            switch (response["data"]["ResponseCode"]) {
                case 200:
                    this.reports = response["data"]["Data"];
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Gespeichert',
                        detail: response["data"]["ResponseMessage"],
                        life: 3000
                    });
                    break;
                case 401:
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Fehler',
                        detail: response["data"]["ResponseMessage"],
                        life: 3000
                    });
                    break;
            }
        }
    }
}
</script>

<style scoped>

.entryFooter {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.entryFooter > *{
    margin-right: 20px;
}

.reports {
    padding-top: 50px;
}

.entry > * {
    margin: 10px;
    margin-bottom: 40px;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.msgs {
    flex-grow: 4;
    max-width: 800px;
}

.cardTitle {
    display: flex;
}
</style>
