<template>
    <div v-if="Object.keys(this.data.data).length > 0">
        <table class="table table-bordered sticky-header-table">
            <thead class="thead-light">
            <tr>
                <th scope="col" v-for="label of this.data.labels">{{ label }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="id of Object.keys(this.data.data)" :key="id">
                <th v-if="this.group === false" scope="row">
                    <img :src="this.getImagePathForPatientId(id)" class="patientimage">
                </th>
                <th v-else>
                    <p>{{id}}</p>
                </th>
                <td v-for="dataset of this.data.data[id]">
                    <Chart type="bar" :data="dataset" :width="460" :height="300"/>
                </td>
            </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
export default {
    props: ["data", "group"],

    computed: {
    },
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {
    },

    methods: {
        // return the path to the image of the patient with given patientid.
        getImagePathForPatientId(patientid) {
            return "images/Patientcards/Patient_" + patientid +".jpg";
        }
    }
}
</script>

<style scoped>

td {
    height: 50px;
    width: 50px;
    text-align: center;
    vertical-align: middle;
}

td canvas {
    ;
}
td div{
    display: inline-flex;
}
tr {

}

th {
    height: 50px;
    width: 50px;
    text-align: center;
    vertical-align: middle;
}

.patientimage {
    width: 200px;

}

.sticky-header-table {
    width: 100%;
    border-collapse: collapse;
}

/* Styling für den klebrigen Tabellenkopf */
.sticky-header-table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>

