<template>
    <div v-if="!this.loading">
        <div class="row">
            <h4>Szenario-Editor</h4>
            <Divider align="right">
                <Dropdown style="margin-right: 20px" v-model="this.selectedScenario" :options="this.scenarios" optionLabel="name" :filter="true" placeholder="Scenario auswählen" :showClear="true" @change="verifySession">
                    <template #value="slotProps">
                        <div class="country-item country-item-value" v-if="slotProps.value">
                            <div>{{slotProps.value.name}}</div>
                        </div>
                        <span v-else>
                        {{slotProps.placeholder}}
                    </span>
                    </template>
                    <template #option="slotProps">
                        <div>
                            <div>{{slotProps.option.name}}</div>
                        </div>
                    </template>
                </Dropdown>
                <Button style="margin-right: 10px" label="Löschen" icon="pi pi-trash" class="p-button-warning" @click="this.deleteDialog"></Button>
                <Button style="padding-right: 30px" label="Speichern" icon="pi pi-save" class="p-button-success" @click="saveToDatabase"></Button>
            </Divider>
        </div>
        <form>
            <ScenarioGenerals @delete="this.delete" :game-play-model="this.gamePlayModel" ></ScenarioGenerals>
        </form>
    </div>
    <div class="loading" v-else>
        <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
    </div>
</template>

<script>
import ScenarioGenerals from "./scenarioGenerals";
import Model from "../Models/gamePlayModel";

export default {
    components: {ScenarioGenerals},
    data() {
        return {
            GamePlayModelTemplate: Model,
            gamePlayModel: JSON.parse(JSON.stringify(Model)),
            selectedScenario: "",
            scenarios: [],
            loading: false,
            loadedScenario: "",
            selectedHospitalLayout: ""
        }
    },
    methods: {
        // triggered by savebutton. General and layoutmodel gets pushed to database with scenarioname in generalmodel as id.
        saveToDatabase: function () {
            axios.post(window.location.href + 'api/codebook/scenariostore', {GamePlayModel : this.gamePlayModel})
            .then(response => this.storeScenarioResponse(response));
        },
        // if scenario with same id exists, dialog apears and ask for overwrite this scenario. This method overwrite this scenario in database.
        updateDatabase: function () {
            axios.post(window.location.href + 'api/codebook/scenarioupdate', {GamePlayModel : this.gamePlayModel})
                .then(response => this.storeScenarioResponse(response));
        },
        // after models where send to the server, this method is triggered with serverresponse
        storeScenarioResponse: function(response) {
            switch (response["data"]["ResponseCode"]) {
                case 200:
                    this.loadScenarioIds();
                    this.selectedScenario = {name: this.gamePlayModel.ScenarioName, code: this.gamePlayModel.ScenarioName};
                    this.$toast.add({severity:'success', summary: 'Gespeichert', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
                case 403:
                    this.$toast.add({severity:'error', summary: 'Fehler', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
                case 409: {
                    this.$confirm.require({
                        message: 'Überschreiben?',
                        header: response["data"]["ResponseMessage"],
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => {
                            this.updateDatabase();
                        },
                        reject: () => {

                        }
                    });
                    break;
                }
            }
        },
        // send loadrequest to server for a scenario with given id, set in selectedscenario dropdown
        loadScenario: function() {
            axios.get(window.location.href + 'api/codebook/getscenario?scenarioid=' + this.selectedScenario["value"])
                    .then(response => this.loadScenarioResponse(response))
            },
        // after loadrequest is send to server, this method is triggered with response that contains layout and general model and set it
        loadScenarioResponse: function(response) {
            this.gamePlayModel = JSON.parse(response["data"]["ResponseMessage"]).GamePlayModel;
            this.loadedScenario = this.selectedScenario;
            this.loading = false;
        },
        // set all models empty
        loadEmptyValues: function() {
            this.gamePlayModel = JSON.parse(JSON.stringify(this.GamePlayModelTemplate));
            this.loadedScenario = this.selectedScenario;
            this.loading = false;
        },
        // send request to server asking for all scenarioids
        loadScenarioIds: function() {
            axios.get(window.location.href + 'api/codebook/scenarioids')
                .then(response => this.loadScenarioIdsResponse(response));
        },
        // after loadrequest this method is triggered with response and set it in scenarios var
        loadScenarioIdsResponse: function(response) {
            this.scenarios = [];
            let scenarioKeys = JSON.parse(response["data"].ResponseMessage)["Keys"];
            scenarioKeys.forEach(key => {
                this.scenarios.push({name: key, value : key})
            });
        },
        // look if a different key is selected and trigger reload if so
        verifySession: function () {
            if (this.loadedScenario != this.selectedScenario) {
                this.loading = true;
                if (this.selectedScenario)
                    this.loadScenario();
                else
                    this.loadEmptyValues();
                return false;
            }
            return true;
        },
        deleteDialog: function() {
            this.$confirm.require({
                message: 'Soll das Szenario mit Name: -' + this.gamePlayModel.ScenarioName + '- wirklich gelöscht werden?',
                header: 'Wirklich löschen?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.delete();
                },
                reject: () => {

                }
            });
        },
        // callback if scenario was deleted. Load scenarioids new and reset view
        delete: function() {
            axios.get(window.location.href + 'api/codebook/deletescenario?scenarioid=' + this.gamePlayModel.ScenarioName)
                .then(response => this.deleteResponse(response));
        },
        deleteResponse: function(response) {
            switch (response["data"]["ResponseCode"]) {
                case 200:
                    this.loading = true;
                    this.selectedScenario = null;
                    this.loadScenarioIds();
                    this.verifySession();
                    this.$toast.add({severity:'success', summary: 'Gelöscht', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
                case 403:
                    this.$toast.add({severity:'error', summary: 'Fehler', detail:response["data"]["ResponseMessage"], life: 3000});
                    break;
            }
        }
    },
    mounted() {
        // if app starts first of all ask server for scenarios
        this.loadScenarioIds();
    }
}
</script>

<style scoped>
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
</style>
