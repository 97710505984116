const therapies = [
    {name: "Übergabe", code: 0 },
    {name: "Sichtung", code: 1 },
    {name: "Primary_Survey", code: 2 },
    {name: "eFAST", code: 3 },
    {name: "Röntgen", code: 4 },
    {name: "CT", code: 5 },
    {name: "ETC", code: 6 },
    {name: "DCS", code: 7 },
    {name: "TASC", code: 8 },
    {name: "Kons_Ther", code: 9 },
    {name: "Keine_Ther", code: 10 },
    {name: "AWR", code: 11 },
    {name: "Stationäre_Aufnahme", code: 12 },
    {name: "keine_weiteren_Massnahmen", code: 13 },
    {name: "All", code: -1 }
];

const codebookPersonal = [
    {name: "ANA", code: 4 },
    {name: "CHIR", code: 15 },
    {name: "OU", code: 26 },
    {name: "ZNA", code: 37 },
    {name: "All", code: 1 },
]

const codebookPersons = [
    {name: "Arzt ANA 1", code: 6},
    {name: "Arzt ANA 2", code: 7},
    {name: "Arzt ANA 3", code: 8},
    {name: "Arzt ANA 4", code: 9},
    {name: "Pfleger ANA 1", code: 11},
    {name: "Pfleger ANA 2", code: 12},
    {name: "Pfleger ANA 3", code: 13},
    {name: "Pfleger ANA 4", code: 14},
    {name: "Arzt CHIR 1", code: 17},
    {name: "Arzt CHIR 2", code: 18},
    {name: "Arzt CHIR 3", code: 19},
    {name: "Arzt CHIR 4", code: 20},
    {name: "Pfleger CHIR 1", code: 22},
    {name: "Pfleger CHIR 2", code: 23},
    {name: "Pfleger CHIR 3", code: 24},
    {name: "Pfleger CHIR 4", code: 25},
    {name: "Arzt OU 1", code: 28},
    {name: "Arzt OU 2", code: 29},
    {name: "Arzt OU 3", code: 30},
    {name: "Arzt OU 4", code: 31},
    {name: "Pfleger OU 1", code: 33},
    {name: "Pfleger OU 2", code: 34},
    {name: "Pfleger OU 3", code: 35},
    {name: "Pfleger OU 4", code: 36},
    {name: "Arzt ZNA 1", code: 39},
    {name: "Arzt ZNA 2", code: 40},
    {name: "Arzt ZNA 3", code: 41},
    {name: "Arzt ZNA 4", code: 42},
    {name: "Pfleger ZNA 1", code: 44},
    {name: "Pfleger ZNA 2", code: 45},
    {name: "Pfleger ZNA 3", code: 46},
    {name: "Pfleger ZNA 4", code: 47},

];

const patients = [
    {name: "A", code: "A"},
    {name: "B", code: "B"},
    {name: "1", code: "1"},
    {name: "2", code: "2"},
    {name: "3", code: "3"},
    {name: "4", code: "4"},
    {name: "5", code: "5"},
    {name: "6", code: "6"},
    {name: "7", code: "7"},
    {name: "8", code: "8"},
    {name: "9", code: "9"},
    {name: "10", code: "10"},
    {name: "11", code: "11"},
    {name: "12", code: "12"},
    {name: "13", code: "13"},
    {name: "14", code: "14"},
    {name: "15", code: "15"},
    {name: "16", code: "16"},
    {name: "17", code: "17"},
    {name: "18", code: "18"},
    {name: "19", code: "19"},
    {name: "20", code: "20"},
    {name: "21", code: "21"},
    {name: "22", code: "22"},
    {name: "23", code: "23"},
    {name: "24", code: "24"},
    {name: "25", code: "25"},
    {name: "26", code: "26"},
    {name: "27", code: "27"},
    {name: "28", code: "28"},
    {name: "29", code: "29"},
    {name: "30", code: "30"},
    {name: "31", code: "31"},
    {name: "32", code: "32"},
    {name: "33", code: "33"},
    {name: "34", code: "34"},
    {name: "35", code: "35"},
    {name: "36", code: "36"},
    {name: "37", code: "37"},
    {name: "38", code: "38"},
    {name: "39", code: "39"},
    {name: "40", code: "40"},
    {name: "41", code: "41"},
    {name: "42", code: "42"},
    {name: "43", code: "43"},
    {name: "44", code: "44"},
    {name: "45", code: "45"},
    {name: "46", code: "46"},
]

const locations =  [
    {name: "AWR" , code: "AWR"},
    {name: "NAS" , code: "NAS"},
    {name: "Notaufnahme" , code: "Notaufnahme"},
    {name: "IMC" , code: "IMC"},
    {name: "OP" , code: "OP"},
    {name: "Radiologie" , code: "Radiologie"},
    {name: "Schockraum" , code: "Schockraum"},
    {name: "Sichtungsplatz" , code: "Sichtungsplatz"}
]

const counts =  [
    {name: 1 , code: "1"},
    {name: 2 , code: "2"},
    {name: 3 , code: "3"},
    {name: 4 , code: "4"},
    {name: 5 , code: "5"},
    {name: 6 , code: "6"},
    {name: 7 , code: "7"},
    {name: 8 , code: "8"},
    {name: 9 , code: "9"},
    {name: 10 , code: "10"}
]

const functions= [
    {name: "Alle roten Steine entfernen", code: "DeleteRedStones"},
    {name: "Rote Steine entfernen", code: "DeleteRedStonesCount"},
    {name: "Rote Steine setzen", code: "SetRedStones"},
]

function getParamsForFunction(name) {

    switch (name) {
        case "DeleteRedStones":
            return []
        case "DeleteRedStonesCount":
        case "SetRedStones":
            return {"Lokation" : locations, "Anzahl" : counts, "Runden" : counts}
        default:
            return []
    }
}

function splitParameter(parameter) {
    if (parameter === null)
        return ""

    let para = parameter.split(",");
    return para;
}


const eventCards = [
    {name: "Transportteam fehlt", code: 0},
    {name: "Instrumentarium noch im Steri", code: 1},
    {name: "Reanimation in der Einleitung", code: 2},
    {name: "Versorgungsengpass in der Notaufnahme", code: 3},
    {name: "CT-Ausfall", code: 4},
    {name: "Hausinterner Notfall", code: 5},
    {name: "AWR hat einen zusätzlichen Platz", code: 6},
    {name: "Zusätzlicher Beatmungsplatz", code: 7},
    {name: "ICU meldet 1 freies Bett", code: 8},
    {name: "IMC meldet 1 freies Bett", code: 9},
    {name: "ICU-Verlegung", code: 10},
    {name: "Glück im Unglück", code: 11},
]

const alarmCards = [
    {name: "DeleteRedStones", code: "DeleteRedStones"},
    {name: "DeleteRedStonesCount", code: "DeleteRedStonesCount"},
    {name: "SetRedStones", code: "SetRedStones"},
]




export default {
    therapies, codebookPersonal, patients, functions, getParamsForFunction, splitParameter, codebookPersons
}

