<template>
    <div v-if="Object.keys(this.data.data).length > 0">
        <table class="table table-bordered sticky-header-table">
            <thead class="thead-light">
                <tr>
                    <th scope="col" v-for="label of this.data.labels">{{ label }}</th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="id of Object.keys(this.data.data)" :key="id">
                <th scope="row">{{id}}</th>
                <td v-for="[label, data] of Object.entries(this.data.data[id])">
                    <div v-if="Array.isArray(data)">
                        <div class="button-container" v-for="[groupid, value] of Object.entries(data)" :key="groupid">
                            <Button class="p-button-rounded limited-button" @click="this.$parent.loadPatientWithId(id, value)">{{value}}</Button>
                        </div>
                    </div>
                    <div class="button-container" v-else>
                        <Button class="p-button-rounded limited-button">{{data}}</Button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ["data", "group"],
    data() {
        return {
        }
    },
    created() {

    },
    computed: {
    },
}
</script>

<style scoped>

td div{
    display: inline-flex;
}

.sticky-header-table {
    width: 100%;
    border-collapse: collapse;
}

/* Styling für den klebrigen Tabellenkopf */
.sticky-header-table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.button-container {
    margin: 1px;
}
</style>

